import React from 'react';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';

import { theme } from 'sitedata';

const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      body > #___gatsby,
      body > #___gatsby > #gatsby-focus-wrapper {
        height: 100%;
        > div:first-of-type {
          height: 100%;
        }
      }

      body {
        font-family: 'Inter', sans-serif;
        white-space: pre-line;
      }

      hr {
        border-width: 0 0 1px 0;
      }

      #modal-root {
        width: 100%;
      }

      .Toaster {
        height: unset;

        .Toaster__manager-bottom {
          margin-bottom: 10px !important;
        }
      }
      .scrollbar-custom {
        scrollbar-width: thin;
        scrollbar-color: #c7c7ca ${theme.colors.primary};
        &::-webkit-scrollbar {
          position: fixed;
          width: 4px;
          height: 4px;
          background: #c7c7ca;
        }
        &::-webkit-scrollbar-thumb {
          background: ${theme.colors.primary};
        }
        &::-webkit-scrollbar-corner {
          background: #c7c7ca;
        }
      }
    `}
  />
);

const ThemeWrapper: React.FC = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={theme} {...props}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
