import React from 'react';
import { styled } from 'styles';
import clsx from 'clsx';
import { Link } from 'gatsby';
import useSitedataAssets from 'hooks/useSitedataAssets';

export interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
  to?: string;
  compact?: boolean;
}

const Logo: React.FC<LogoProps> = ({ className, to, compact, ...props }) => {
  const { logo } = useSitedataAssets();

  // const logoSrc = compact ? logoSmall : logo;
  const logoSrc = logo;

  if (!logoSrc) return null;

  return (
    <StyledLogo className={clsx('logo', { compact }, className)} {...props}>
      {to ? (
        <Link to={to} className="logo__link" data-cy="logo_link">
          <img src={logoSrc} alt="logo" />
        </Link>
      ) : (
        <img src={logoSrc} alt="logo" />
      )}
    </StyledLogo>
  );
};

export default Logo;

const StyledLogo = styled.div`
  display: inline-block;
  font-weight: bold;
  width: 40px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-right: auto;
  flex-shrink: 0;

  a {
    color: #000;
    text-decoration: none;
  }

  img {
    width: 100%;
  }
`;
