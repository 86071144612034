/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import RCPagination, { PaginationProps } from 'rc-pagination';
import { styled } from 'styles';

const locale = {
  // Options.jsx
  items_per_page: '/ page',
  jump_to: 'Go to',
  jump_to_confirm: 'confirm',
  page: '',

  // Pagination.jsx
  prev_page: 'Previous Page',
  next_page: 'Next Page',
  prev_5: 'Previous 5 Pages',
  next_5: 'Next 5 Pages',
  prev_3: 'Previous 3 Pages',
  next_3: 'Next 3 Pages'
};

const Pagination: React.FC<PaginationProps> = props => {
  const itemRender = (current, type, element) => {
    switch (type) {
      case 'page':
        return <button>{current}</button>;
      case 'jump-prev':
      case 'jump-next':
        return '...';
      case 'prev':
      case 'next':
        return null;
      default:
        return element;
    }
  };

  return (
    <StyledPagination
      itemRender={itemRender}
      pageSize={30}
      showTitle={false}
      locale={locale}
      {...props}
    />
  );
};

export default Pagination;

const StyledPagination = styled(RCPagination)`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 2px;
    outline: none;

    button {
      width: 24px;
      height: 24px;
      border: 1px solid ${props => props.theme.colors.borderColor};
      border-radius: ${props => props.theme.misc.borderRadius};
      font-size: 10px;
      line-height: 12px;
      padding: 0;
      background: #fff;
      outline: none;
      cursor: pointer;
      &:hover {
        border-color: ${props => props.theme.colors.pastelGray};
        transition: ${props => props.theme.transitions.standart};
      }
    }

    &.rc-pagination-item-active button {
      border-color: ${props => props.theme.colors.primary};
    }
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    display: none;
  }
`;
