import React, { useState, useEffect } from 'react';

import { getMultipleListingDetails } from 'api/listings';
import notify from 'notify';
import { getFirstError } from 'utils';
import { formatListingDetailsToCardData } from 'utils/formatters';
import ListingCardSkeleton from './ListingCardSkeleton';
import ListingCard from './ListingCard';

interface ListingCardMapListProps extends React.HTMLAttributes<HTMLDivElement> {
  ids: string[];
  onOutsideClick?: () => void;
}

const ListingCardMapList: React.FC<ListingCardMapListProps> = ({ ids }) => {
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<ListingDetails[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const listings = await getMultipleListingDetails(ids);
        setLoading(false);
        setListings(listings);
      } catch (err) {
        notify(getFirstError(err) || err.message);
      }
    })();
  }, [ids]);

  return (
    <>
      {loading && ids.map(item => <ListingCardSkeleton key={item} />)}
      {listings.map(item => (
        <ListingCard key={item.id} data={formatListingDetailsToCardData(item)} />
      ))}
    </>
  );
};

export default ListingCardMapList;
