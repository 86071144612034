import React, { useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import useSavedListings from 'hooks/useSavedListings';
import { getAddress } from 'utils/listing';
import noImage from 'assets/images/placeholder_no_image.jpg';
import ListingCarousel from 'components/ListingCarousel';
import Icon from '../Icon';
import Image from '../Image';

export interface ListingCardProps extends React.HTMLAttributes<HTMLDivElement> {
  compact?: boolean;
  data: ListingCardData;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const numberFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 });

const ListingCard: React.FC<ListingCardProps> = ({ className, compact, data, ...props }) => {
  const { saveListing, savedListings } = useSavedListings();
  const saved = savedListings?.includes(data.id);
  const [photos, setPhotos] = useState(Array.from(new Set(data.images)));

  const renderArrowButton = (type: 'prev' | 'next') => (
    clickHandler: () => void,
    hasPrev: boolean
  ) => {
    if (!hasPrev) return null;

    const handleClick = e => {
      e.preventDefault();
      clickHandler();
    };

    return (
      <StyledCarouselButton onClick={handleClick} className={clsx('carousel-button', type)}>
        <Icon name="chevron" style={{ transform: `rotate(${type === 'prev' ? 90 : -90}deg)` }} />
      </StyledCarouselButton>
    );
  };

  const renderIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    _index: number,
    label: string
  ) => {
    const handleClick = e => {
      e.preventDefault();
      clickHandler(e);
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <li
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        tabIndex={0}
        aria-label={label}
        onClick={handleClick}
        className={clsx('dot', { selected: isSelected })}
      />
    );
  };

  const Wrapper = data.url
    ? ({ children, ...props }) => (
        <StyledLink
          href={`/listing/${data.url}`}
          target="_blank"
          className="property-card-wrapper"
          data-cy={`listing_card_${data.id}`}
          {...props}>
          {children}
        </StyledLink>
      )
    : ({ children, ...props }) => (
        <div className="property-card-wrapper" {...props}>
          {children}
        </div>
      );

  return (
    <Wrapper {...props}>
      <StyledListingCard className={clsx('property-card', { compact }, className)}>
        <button
          onClick={e => saveListing(e, data.id)}
          className={clsx('favorite-button', {
            saved
          })}
          data-cy={`favorite_button_${data.id}`}>
          {!saved && <Icon name="heart" />}
          {saved && <Icon name="heart-filled" />}
        </button>

        {photos.length > 0 ? (
          <ListingCarousel
            images={photos}
            showStatus={false}
            showThumbs={false}
            renderIndicator={renderIndicator}
            renderArrowPrev={renderArrowButton('prev')}
            renderArrowNext={renderArrowButton('next')}
            renderImage={(item, idx) => (
              <Image
                cloudFront={{ size: '400x300', key: item }}
                key={item}
                alt={`photo-${idx}`}
                onError={() => setPhotos(photos => photos.filter(photo => photo !== item))}
              />
            )}
            showIndicators={photos.length > 1}
            className="property-card__carousel"
          />
        ) : (
          <img src={noImage} className="img-error" alt="img-error" />
        )}

        <div className="property-card__info">
          <div className="property-card__info-line price" data-cy={`property_price_${data.id}`}>
            {currencyFormatter.format(data.price)}{' '}
            {data.daysOnMarket <= 5 && <span className="property-card__new-tag">New</span>}
          </div>
          <div className="property-card__info-line" data-cy={`property_info_${data.id}`}>
            {data.beds} Bd · {data.baths} Ba · {numberFormatter.format(data.sqft)} ft
            <sup>2</sup>
          </div>
          <div className="property-card__info-line" data-cy={`property_address_${data.id}`}>
            {data.address1}
            {/* why "undefined" string is even possible? Needs investigation. */}
            {data.address2 === 'undefined' ? '' : `, ${data.address2}`}
          </div>
          <div className="property-card__info-line" data-cy={`property_city_${data.id}`}>
            {getAddress(data)}
          </div>
        </div>
      </StyledListingCard>
    </Wrapper>
  );
};

export default ListingCard;

const StyledListingCard = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  width: 320px;
  height: 276px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  color: inherit;
  text-decoration: none;

  &.compact {
    width: 200px;
    height: 232px;
    padding: 8px;

    .property-card__carousel,
    .property-card__photo,
    .img-error {
      height: 112px;
      border-radius: 3px;
      margin-bottom: 8px;
    }

    .property-card__info {
      padding: 0;
      font-family: 'Inter';
    }
  }
  .carousel-button {
    opacity: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .carousel-button {
      opacity: 1;
    }
  }

  &:hover {
    .carousel-button {
      opacity: 1;
    }
  }

  .property-card__new-tag {
    background: ${props => props.theme.colors.primary};
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    border-radius: 3px;
    padding: 2px 4px;
    margin-left: 8px;
    vertical-align: middle;
  }

  .favorite-button {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: ${props => props.theme.transitions.standart};

    .icon {
      fill: #fff;
    }

    &.saved {
      background: ${props => props.theme.colors.primary};
    }
    &:hover:not(.saved) {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .property-card__photo,
  .img-error {
    width: 100%;
    height: 155px;
    border-radius: 3px 3px 0 0;
    object-fit: cover;
  }

  .img-error {
    background: ${props => props.theme.colors.harborGray};
  }

  .property-card__carousel {
    width: 100%;
    height: 155px;
    border-radius: 3px 3px;

    .carousel {
      height: 100%;

      .slide {
        background: ${props => props.theme.colors.harborGray};
      }

      .slider-wrapper,
      .slider,
      img {
        height: 100%;
      }

      img {
        object-fit: cover;
      }

      .dot {
        margin: 0 2px;
        box-shadow: none;
        background: rgba(255, 255, 255, 0.4);
        outline: none;

        &.selected {
          background: #fff;
        }
      }
    }
  }

  .property-card__info {
    padding: 12px;
    font-size: 14px;
    line-height: 24px;

    .price {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const StyledCarouselButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.35);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  position: absolute;
  z-index: 10;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: ${props => props.theme.transitions.standart};
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  &.prev {
    top: 50%;
    left: 12px;
  }
  &.next {
    top: 50%;
    right: 12px;
  }

  .icon {
    fill: #fff;
  }
`;

const StyledLink = styled.a`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  cursor: pointer;
`;
