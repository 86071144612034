import React from 'react';
import { styled } from 'styles';
import clsx from 'clsx';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  label?: React.ReactNode;
  ref?: any;
}

const Textarea: React.FC<TextareaProps> = React.forwardRef(
  ({ className, label, ...props }, ref) => {
    return (
      <StyledTextarea className={clsx('textarea', className)}>
        <span className="textarea__label">{label}</span>
        <textarea className="textarea-input" {...props} ref={ref}></textarea>
      </StyledTextarea>
    );
  }
);

const StyledTextarea = styled.div`
  width: 176px;
  .textarea-input {
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.borderColor};
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 8px;
    outline: none;
    resize: none;
    box-sizing: border-box;
  }
  .textarea {
    &__label {
      display: block;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
`;

export default Textarea;
