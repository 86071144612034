import { urlEncodeBody } from 'utils';
import { mapFeaturedListings, mapListingCardDetails, mapListing } from 'utils/mapping';
import { getStorageObject, setStorageObject } from 'utils/localStorage';
import { LOCAL_STORAGE } from 'consts';
import { fetch, fetchWithToken, commonApiUrl } from './api';
import { getAddress } from 'utils/listing';

const { SAVED_ITEMS_STORAGE_KEY } = LOCAL_STORAGE;

export async function getFeaturedListings() {
  const response = await fetch('/featured');
  const data = await response.json();

  return mapFeaturedListings(data);
}

type ListingsSearchParams = Partial<{
  // property_type: ListingType[];
  city: string;
  county: string;
  postal_code: string;
  state: string;
  street: string;
  bedrooms_min: number;
  bedrooms_max: number;
  bathrooms_min: number;
  bathrooms_max: number;
  listing_price_min: number;
  listing_price_max: number;
  sqft_min: number;
  sqft_max: number;
  listing_status: string[];
  year_built_min: number;
  year_built_max: number;
  stories_min: number;
  stories_max: number;
  map_top_left_longitude: number;
  map_top_left_latitude: number;
  map_right_bottom_longitude: number;
  map_right_bottom_latitude: number;
  no_map: boolean;
  offset: number;
}>;

type SimilarListingsSearchParams = Partial<{
  county: string;
  listing_price_min: number;
  listing_price_max: number;
  listing_status: string;
}>;

type GetListingsSearchResponse = {
  listings: ListingCardData[];
  markers: ListingsSearchMarker[];
  pagination: { page: number; total_pages: number; total_results: number };
};

export async function getListingsSearch(
  params?: ListingsSearchParams,
  config?: RequestInit
): Promise<GetListingsSearchResponse> {
  const response = await fetch(`${commonApiUrl}/listings/search`, {
    query: urlEncodeBody(params).toString(),
    ...config
  });
  const { listings, map, pagination: paginationArr } = await response.json();

  if (!listings)
    return {
      listings: [],
      markers: [],
      pagination: { page: 0, total_pages: 0, total_results: 0 }
    };

  const mappedListings: ListingCardData[] = listings.map(mapListingCardDetails);

  const markers = map.map(item => ({
    id: item.id,
    price: item.current_price,
    latitude: item.coordinates.latitude,
    longitude: item.coordinates.longitude
  }));

  const pagination = paginationArr[0]
    ? {
        page: paginationArr[0].page_offset + 1,
        total_pages: paginationArr[0].total_pages,
        total_results: paginationArr[0].total_listings
      }
    : { page: 0, total_pages: 0, total_results: 0 };

  const result = { listings: mappedListings, markers, pagination };
  return result;
}

export async function getAutocompleteSuggestions(
  searchString: string
): Promise<GroupedSuggestions> {
  const response = await fetch(
    `${commonApiUrl}/listings/autocomplete?search_string=${searchString}`
  );
  const data = await response.json();

  if (Array.isArray(data)) return {};

  const results = {};

  Object.entries(data).forEach(([key, items]: any) => {
    if (!items.length) return;

    results[key] = items.map(item => {
      const suggestion: any = {};
      Object.entries(item).forEach(([key, value]: [string, any]) => {
        if (key === '_id') suggestion.name = String(value);
        // eslint-disable-next-line prefer-destructuring
        else suggestion[key] = value[0];
      });
      return suggestion;
    });
  });

  return results as GroupedSuggestions;
}

export async function getListingDetails(id: string): Promise<ListingDetails> {
  const response = await fetch(`/listings/listing/${id}`);
  const data = await response.json();

  return mapListing(data) as ListingDetails;
}

export async function getMultipleListingDetails(ids: string[]): Promise<ListingDetails[]> {
  const payload = {};
  ids.forEach((item, idx) => {
    payload[`listings[${idx}]`] = item;
  });

  const response = await fetch(`${commonApiUrl}/listings/multi_listings`, {
    method: 'POST',
    body: urlEncodeBody(payload)
  });
  const data = await response.json();

  return data.map(mapListing);
}

export async function getSimilarListings(
  params?: SimilarListingsSearchParams
): Promise<ListingCardData[]> {
  const response = await fetch(`${commonApiUrl}/listings/search`, {
    query: urlEncodeBody(params).toString()
  });
  const { listings } = await response.json();

  if (!listings) return [];

  return listings.map(mapListingCardDetails);
}

export async function saveSearch(url: string) {
  await fetchWithToken('/broker_site/searches/saved', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: urlEncodeBody({ url })
  });
}

const paramsToObject = (entries: IterableIterator<[string, string]>) => {
  const result = {};
  Array.from(entries).forEach(([key, value]) => {
    result[key] = result[key] ? [result[key], value].flat() : value;
  });
  return result;
};

export async function getSavedSearch(): Promise<SavedSearch[]> {
  const response = await fetchWithToken('/broker_site/searches/saved');
  const text = await response.text();
  if (!text) return [];
  // const data = await response.json();
  const data = JSON.parse(text);

  return data.map(item => {
    const search = item.url.split('?')[1];
    const params = search ? paramsToObject(new URLSearchParams(search).entries()) : {};
    return { id: item.id, url: item.url, params };
  });
}

export async function deleteSavedSearch(id: string) {
  await fetchWithToken(`/broker_site/searches/saved/${id}`, { method: 'DELETE' });
}

export async function getSavedListings(): Promise<{
  listings: ListingCardData[];
  markers: ListingsSearchMarker[];
}> {
  const response = await fetchWithToken('/broker_site/listings/saved');
  const data = await response.json();
  const { listings, map } = data;

  const mappedListings: ListingCardData[] = listings.map(mapListingCardDetails);

  const markers = map
    .filter(item => {
      if (
        typeof item.coordinates.latitude === 'number' &&
        typeof item.coordinates.longitude === 'number'
      ) {
        return true;
      }
      // eslint-disable-next-line no-console
      console.warn('[getSavedListings]: Marker with invalid coordinates');
      return false;
    })
    .map(item => ({
      id: item.id,
      price: item.current_price,
      latitude: item.coordinates.latitude,
      longitude: item.coordinates.longitude
    }));

  return { listings: mappedListings, markers };
}

export async function saveListing(id: string): Promise<string[]> {
  await fetchWithToken('/broker_site/listings/saved', {
    method: 'POST',
    body: urlEncodeBody({ listing_id: id })
  });

  const savedItems: SavedItemsData | undefined = getStorageObject(SAVED_ITEMS_STORAGE_KEY);
  const savedListings = savedItems?.savedListings || [];
  const newSavedListings = [...savedListings, id];
  const newSavedItemsData: SavedItemsData = {
    savedSearches: savedItems?.savedSearches || [],
    savedListings: newSavedListings
  };
  setStorageObject(SAVED_ITEMS_STORAGE_KEY, newSavedItemsData);
  return newSavedListings;
}

export async function deleteSavedListing(id: string): Promise<string[]> {
  await fetchWithToken(`/broker_site/listings/saved/${id}`, {
    method: 'DELETE'
  });

  const savedItems: SavedItemsData | undefined = getStorageObject(SAVED_ITEMS_STORAGE_KEY);
  const savedListings = savedItems?.savedListings || [];
  const newSavedListings = savedListings.filter(item => item !== id);
  const newSavedItemsData: SavedItemsData = {
    savedSearches: savedItems?.savedSearches || [],
    savedListings: newSavedListings
  };
  setStorageObject(SAVED_ITEMS_STORAGE_KEY, newSavedItemsData);
  return newSavedListings;
}

export async function shareListing(emails: string, listingUrl: string) {
  await fetchWithToken('/broker_site/listings/share', {
    method: 'POST',
    body: urlEncodeBody({ email: emails, url: listingUrl })
  });
}

export async function getOffers() {
  const response = await fetchWithToken(`/listings/offers`);
  const data = await response.json();
  const listings = data.map(item => ({
    propertyAddress: `${item.address1}, ${getAddress(item)}`
  }));

  return listings;
}
