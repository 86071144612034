/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import 'typeface-inter';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'modern-normalize';
import React from 'react';
import { AuthContextWrapper } from './src/auth/authContext';
import { AuthModalContextWrapper } from './src/auth/AuthModalContext';
import { ThemeWrapper } from './src/styles';
import I18nWrapper from './src/i18n/I18nWrapper';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <I18nWrapper>
      <ThemeWrapper>
        <AuthContextWrapper>
          <AuthModalContextWrapper>
            {element}
            <div id="modal-root" />
          </AuthModalContextWrapper>
        </AuthContextWrapper>
      </ThemeWrapper>
    </I18nWrapper>
  );
};
