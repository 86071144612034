import React, { useRef } from 'react';
import notify from 'notify';

import { styled } from 'styles';
import { Icon } from 'components';

const allowedFileTypes = new Set([
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'application/pdf',
  'application/msword',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]);

export enum FileInputACCEPT {
  IMAGES = 'image/*',
  ALL = ''
}

interface FileInputProps {
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  onFileUpload: (value: File) => void;
  onFileRemove?: (value: File) => void;
  files?: Array<File>;
  accept?: FileInputACCEPT;
}

const FileInput: React.FC<FileInputProps> = ({
  label,
  placeholder,
  onFileUpload,
  onFileRemove,
  files,
  accept = FileInputACCEPT.IMAGES
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async e => {
    const file: File = e.target.files[0];
    if (!file) return;
    if (!allowedFileTypes.has(file.type)) {
      notify('This file type is not allowed');
      return;
    }
    fileInputRef.current!.value = '';
    onFileUpload(file);
  };

  const openFileUpload = e => {
    e.preventDefault();
    fileInputRef.current!.click();
  };

  return (
    <StyledFileInput className="file-input">
      {label && <span className="file-input__label">{label}</span>}
      <button className="file-input__button" onClick={openFileUpload}>
        {placeholder && <span className="file-input__text">{placeholder}</span>}
        <Icon name="file" />
      </button>
      <input
        ref={fileInputRef}
        accept={accept}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
      {files && (
        <div className="file-input__files">
          {files.map(file => (
            <span key={file.name} className="file-input__file">
              <Icon name="file" />
              &nbsp;&nbsp;
              <span className="file-input__file__name">{file.name}</span>&nbsp;&nbsp;
              {onFileRemove && <Icon name="mini-x" size={8} onClick={() => onFileRemove(file)} />}
            </span>
          ))}
        </div>
      )}
    </StyledFileInput>
  );
};

export default React.memo(FileInput);

const StyledFileInput = styled.div`
  .file-input {
    &__label {
      display: block;
      margin: 0 0 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #000;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 36px;
      padding: 0 8px;
      color: ${props => props.theme.colors.etherealGrey};
      border: 1px solid ${props => props.theme.colors.borderColor};
      border-radius: ${props => props.theme.misc.borderRadius};
      background: transparent;
      font-size: 12px;
      text-overflow: ellipsis;
      outline: none;
      cursor: pointer;
    }
    &__file {
      margin: 8px 0 0;
      display: inline-flex;
      align-items: center;
      padding: 4px;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid #000;
      border-radius: ${props => props.theme.misc.borderRadius};
      &__name {
        max-width: 112px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & ~ * {
        margin-left: 8px;
      }
      .icon-mini-x {
        fill: ${props => props.theme.colors.primary};
        cursor: pointer;
      }
    }
  }
`;
