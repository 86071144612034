import React, { useState } from 'react';
import { useKeyPress } from 'hooks';
import { Icon } from 'components';
import { styled } from 'styles';

enum SuggestionTypes {
  City = 'city',
  Area = 'area',
  PostalCode = 'postal_code',
  Street = 'street',
  HighSchool = 'high_school',
  MiddleSchool = 'middle_or_junior_school',
  ElementarySchool = 'elementary_school',
  County = 'county',
  Places = 'places',
  ListingId = 'listing_id'
}

const categoryNames = {
  area: 'Area',
  places: 'Places',
  county: 'County',
  city: 'City',
  street: 'Street',
  postal_code: 'Postal Code',
  elementary_school: 'Elementary School',
  middle_or_junior_school: 'Middle or junior school',
  high_school: 'High school',
  listing_id: 'Listing ID'
};

const categoryOrder = {};
Object.values(SuggestionTypes).forEach((key, idx) => {
  categoryOrder[key] = idx;
});

interface SearchResultsProps extends React.HTMLAttributes<HTMLDivElement> {
  items: GroupedSuggestions;
  onSuggestionClick: (value: Suggestion) => void;
}

const SearchResults: React.FC<SearchResultsProps> = ({ items, onSuggestionClick, ...props }) => {
  const [focus, setFocus] = useState<number>();

  const getSuggestionIcon = (key: SuggestionTypes) => {
    switch (key) {
      case SuggestionTypes.ElementarySchool:
      case SuggestionTypes.MiddleSchool:
      case SuggestionTypes.HighSchool:
        return 'suggestion-school';
      case SuggestionTypes.ListingId:
        return 'suggestion-id';
      case SuggestionTypes.Places:
      default:
        return 'suggestion-place';
    }
  };

  useKeyPress(['ArrowDown', 'ArrowUp'], e => {
    e.preventDefault();
    const options = document.getElementsByClassName('autocomplete-suggestion');
    const idxOffset = e.key === 'ArrowDown' ? 1 : -1;
    const optionToFocusIdx = focus === undefined ? 0 : focus + idxOffset;
    const optionToFocus = options[optionToFocusIdx] as HTMLButtonElement;
    if (optionToFocus) {
      optionToFocus.focus();
      setFocus(optionToFocusIdx);
    }
  });

  return (
    <StyledSearchResults {...props}>
      {Object.entries(items)
        .sort(([a], [b]) => categoryOrder[a] - categoryOrder[b])
        .map(([key, suggestions]: [string, Suggestion[] | undefined]) => (
          <div key={key} id={key}>
            <div className="category__label">{categoryNames[key] || key}</div>
            {suggestions?.map(suggestion => (
              <button
                key={suggestion.name}
                tabIndex={0}
                onClick={() => onSuggestionClick(suggestion)}
                className="autocomplete-suggestion"
                id={`suggestion_${suggestion.name ? suggestion.name.replace(/\s/g, '_') : ''}`}>
                <div className="autocomplete-suggestion__name">
                  <Icon name={getSuggestionIcon(key as any)} /> {suggestion.name}
                </div>
                {suggestion.city && suggestion.state && (
                  <div className="autocomplete-suggestion__info">
                    {suggestion.city}, {suggestion.state.toUpperCase()}
                  </div>
                )}
              </button>
            ))}
          </div>
        ))}
    </StyledSearchResults>
  );
};

export default SearchResults;

const StyledSearchResults = styled.div`
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: -1px;
  width: 100%;
  background: #f7f7f7;
  padding-top: 2px;
  max-height: 216px;
  overflow: auto;

  .category__label {
    padding: 4px 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #4e4e4e;
  }

  .autocomplete-suggestion {
    width: 100%;
    padding: 9px 12px;
    text-align: left;
    background: #fff;
    color: #4e4e4e;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    text-transform: capitalize;

    &:focus {
      background: ${props => props.theme.colors.lightGray};
    }

    &__name {
      display: flex;
      align-items: center;
    }

    &__info {
      padding-left: 18px;
      font-size: 10px;
      line-height: 19px;
      color: #8892a0;
    }

    .icon {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
`;
