import slugify from 'slugify';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

export const numberFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 });

export const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

export const abbreviateNumber = value => {
  /* eslint-disable no-param-reassign */
  if (value > 1000000) value = Math.round(value / 10000) * 10000;
  else if (value > 1000) value = Math.round(value / 1000) * 1000;
  /* eslint-enable no-param-reassign */

  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return value;
};

export const formatListingDetailsToCardData = (listing: ListingDetails): ListingCardData => ({
  id: listing.id,
  favorite: false,
  label: '',
  status: listing.status,
  price: listing.price,
  images: listing.images,
  state: listing.state,
  postalCode: listing.postalCode,
  city: listing.city,
  neighborhood: listing.neighborhood,
  address1: listing.address1,
  address2: listing.address2,
  beds: listing.propertyDetails.beds,
  baths: listing.propertyDetails.baths,
  sqft: listing.propertyDetails.sqft,
  url: `${listing.id}/${slugify(listing.address1, { lower: true, strict: true })}`,
  daysOnMarket: listing.daysOnMarket
});
