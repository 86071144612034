export const urlEncodeBody = (data: object = {}) => {
  const body = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) =>
    value === 0 ? body.set(key, value) : value && body.set(key, value)
  );

  return body;
};

type JeronErrorFormat = {
  success: 0;
  errors: Record<string, string[]>;
};

export const getFirstError = (err: { response: JeronErrorFormat }) => {
  if (!err?.response) return;
  const firstEntry = Object.entries(err.response.errors)[0];
  if (firstEntry) return firstEntry[1][0];
};
