import React, { useState, useRef, useEffect, useContext } from 'react';
import clsx from 'clsx';

import notify from 'notify';
import { styled } from 'styles';
import { getListingDetails } from 'api/listings';
import { abbreviateNumber, formatListingDetailsToCardData } from 'utils/formatters';
import ListingCard from 'components/ListingCard';
import { getFirstError } from 'utils';
import { ClickOutsideWrapper } from 'components';
import MapContext from './mapContext';

interface ListingPinProps extends React.HTMLAttributes<HTMLDivElement> {
  lat: number;
  lng: number;
  data: ListingsSearchMarker;
}

const ListingPin: React.FC<ListingPinProps> = ({ data, ...props }) => {
  const ref = useRef(null);
  const { openPin, setOpenPin } = useContext(MapContext);
  const [listing, setListing] = useState<ListingCardData>();
  const [used, setUsed] = useState(false);
  const open = openPin === data.id;

  const closeTooltip = () => {
    setOpenPin(null);
    setListing(undefined);
  };

  const handleClick = () => {
    if (!used) setUsed(true);
    if (open) closeTooltip();
    else setOpenPin(data.id);
  };

  useEffect(() => {
    if (!open) return;
    (async () => {
      try {
        const listings = await getListingDetails(data.id);
        setListing(formatListingDetailsToCardData(listings));
      } catch (err) {
        notify(getFirstError(err) || err.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <StyledListingPin ref={ref} data-listingid={data.id} {...props}>
      <button onClick={handleClick} className={clsx('pin', { selected: open, used })}>
        ${data.price ? abbreviateNumber(data.price) : '???'}
      </button>
      {open && listing && (
        <ClickOutsideWrapper className="click-outside-wrapper" onOutsideClick={closeTooltip}>
          <ListingCard
            className="map-card"
            data={listing}
            onClick={e => e.stopPropagation()}
            compact
          />
        </ClickOutsideWrapper>
      )}
    </StyledListingPin>
  );
};

export default React.memo(ListingPin);

const StyledListingPin = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 10px;
  height: 10px;
  z-index: 1000;

  .pin {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #fff;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 3px;
    padding: 3px 4px;
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 8px;
    outline: none;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 10px;
      height: 10px;
      transform: translate(-50%, 50%) rotate(45deg);
      background: #fff;
      border: 2px solid ${props => props.theme.colors.primary};
      border-width: 0 2px 2px 0;
    }

    &:hover:not(.selected) {
      color: ${props => props.theme.colors.hoverPin};
      border-color: ${props => props.theme.colors.hoverPin};

      &::after {
        border-color: ${props => props.theme.colors.hoverPin};
      }
    }
    &.selected {
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
      &::after {
        background: ${props => props.theme.colors.primary};
      }
    }
    &.used:not(.selected) {
      border-color: ${props => props.theme.colors.lightViolet};
      color: ${props => props.theme.colors.lightViolet};
      &::after {
        border-color: ${props => props.theme.colors.lightViolet};
      }
    }
  }

  .click-outside-wrapper {
    position: absolute;
    top: 10px;
    z-index: 1000;
  }

  .property-card {
    height: auto;
  }
`;
