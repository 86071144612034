import { useState, useEffect, useContext } from 'react';

import { AuthContext, AuthModalContext } from 'auth';
import { saveListing as saveListingRequest, deleteSavedListing } from 'api/listings';
import notify from 'notify';
import { getFirstError } from 'utils';

const useSavedListings = () => {
  const { authorized, savedItems, setSavedItems } = useContext(AuthContext);
  const { setAuthModalOpen, setMessage } = useContext(AuthModalContext);
  const [savedListings, setSavedListings] = useState<string[]>();
  const savedListingsData = savedListings || savedItems?.savedListings;

  useEffect(() => {
    if (!savedListings) setSavedListings(savedItems?.savedListings);
    if (!authorized) setSavedListings(undefined);
  }, [savedItems, authorized, savedListings]);

  const saveListing = async (listingId: string) => {
    try {
      const data = await saveListingRequest(listingId);
      setSavedListings(data);
      setSavedItems({
        savedListings: [...(savedItems?.savedListings || []), listingId],
        savedSearches: savedItems?.savedSearches || []
      });
    } catch (err) {
      notify(getFirstError(err) || err.message);
    }
  };

  const handleSaveListing = (e: React.MouseEvent<HTMLButtonElement>, listingId: string) => {
    e.preventDefault();
    if (!authorized) {
      setAuthModalOpen(true);
      setMessage('Sign in or register to save your favorite homes');
      return;
    }
    if (!savedListings?.includes(listingId)) saveListing(listingId);
    else unsaveListing(listingId);
  };

  const unsaveListing = async (listingId: string) => {
    try {
      const data = await deleteSavedListing(listingId);
      setSavedListings(data);
    } catch (err) {
      notify(getFirstError(err) || err.message);
    }
  };

  return {
    saveListing: handleSaveListing,
    unsaveListing,
    savedListings: savedListingsData
  };
};

export default useSavedListings;
