import React from 'react';
import { styled } from 'styles';

const InfoSection = () => {
  return (
    <StyledInfoSection className="info-section">
      <div className="info-section-wrapper">
        <p className="info-section__description">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book.
        </p>
      </div>
    </StyledInfoSection>
  );
};

const StyledInfoSection = styled.div`
  padding: 32px 16px;
  .info-section {
    &__description {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
      max-width: 885px;
    }
  }
  .info-section-wrapper {
    background: ${props => props.theme.colors.superLightGray};
    border-radius: ${props => props.theme.misc.borderRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 159px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 23px 16px;
    .info-section-wrapper {
      padding: 40px 24px;
      min-height: auto;
    }
  }
`;

export default InfoSection;
