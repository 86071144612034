import React, { useState } from 'react';
import clsx from 'clsx';
import ReactDatetime, { DatetimepickerProps } from 'react-datetime';
import NumberFormat from 'react-number-format';

import { styled } from 'styles';
import ArrowSVG from 'assets/icons/arrow-simple.svg';
import { Button, ClickOutsideWrapper, Icon } from 'components';
import Input, { InputProps } from 'components/Input';
import dayjs from 'dayjs';

export interface DatePickerProps extends Omit<DatetimepickerProps, 'inputProps'> {
  renderInput?: (props: any, openCalendar: Function, closeCalendar: Function) => JSX.Element;
  inputProps?: InputProps;
  error?: boolean | string;
}

const InputDate: React.FC<DatePickerProps> = ({
  className,
  dateFormat = 'MM/DD/YY',
  inputProps,
  value,
  error,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const renderInput = props => {
    const numberFormatProps = {
      format: '##/##/##',
      mask: '_'
    };

    return (
      <Input
        as={NumberFormat}
        after={
          <Button
            simple
            onClick={() => setOpen(v => !v)}
            type="button"
            className="input-datepicker__calendar-button">
            <Icon name="calendar" />
          </Button>
        }
        error={error}
        {...numberFormatProps}
        {...props}
        onClick={() => setOpen(true)}
      />
    );
  };

  const renderDay = ({ className, onClick, ...props }, currentDate) => {
    const handleClick = e => {
      onClick(e);
      setOpen(false);
    };

    const newProps = {
      ...props,
      className: clsx(className, { rdtPast: currentDate.isBefore(new Date(), 'date') }),
      onClick: handleClick
    };

    return (
      <td data-cy={`date_cell_${currentDate.format('MM/DD/YYYY')}`} {...newProps}>
        {currentDate.date()}
      </td>
    );
  };

  return (
    <ClickOutsideWrapper onOutsideClick={() => setOpen(false)}>
      <StyledDatePicker
        open={open}
        value={value}
        inputProps={inputProps as React.HTMLProps<HTMLInputElement>}
        dateFormat={dateFormat}
        renderInput={renderInput}
        renderDay={renderDay}
        className={clsx('input-datepicker', className)}
        timeFormat={false}
        closeOnSelect={true}
        {...props}
      />
    </ClickOutsideWrapper>
  );
};

export default React.memo(InputDate);

export const DatePickerAdapter = ({ meta, input, ...props }) => {
  return <InputDate error={(meta.modified && meta.error) || false} {...input} {...props} />;
};

const StyledDatePicker = styled(ReactDatetime)`
  display: inline-block;
  width: auto;
  position: relative;

  &:not(.rdtOpen) .rdtPicker {
    display: none;
  }

  .rdtPicker {
    position: absolute;
    z-index: 1000;
    box-sizing: border-box;
    margin: 8px 0;
    top: 100%;
    padding: 12px 16px;
    width: 100%;
    max-width: 300px;
    background: #ffffff;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
    border-radius: ${props => props.theme.misc.borderRadius};

    .rdtDays {
      width: 100%;
      height: 100%;

      table,
      thead,
      tbody {
        display: block;
      }

      tr {
        display: flex;
        justify-content: space-between;
      }

      table {
        width: 100%;

        thead {
          tr:nth-of-type(1) {
            margin-bottom: 17px;

            .rdtSwitch {
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              pointer-events: none;
            }

            .rdtPrev,
            .rdtNext {
              position: relative;
              padding: 0;
              width: 24px;
              height: 24px;
              cursor: pointer;

              span {
                display: block;
                width: 100%;
                height: 100%;
                color: transparent;

                &::after {
                  content: '';
                  left: 0;
                  position: absolute;
                  width: 24px;
                  height: 24px;
                  background-image: url(${ArrowSVG});
                  background-size: 16px 16px;
                  background-position: center;
                  background-repeat: no-repeat;
                }
              }
            }

            .rdtNext {
              span::after {
                transform: rotate(180deg);
              }
            }
          }

          tr:nth-of-type(2) {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid ${props => props.theme.colors.gray};

            .dow {
              width: 30px;
              font-size: 12px;
              line-height: 22px;
              font-weight: normal;
              color: ${props => props.theme.colors.etherealGrey};
            }
          }
        }

        tbody {
          .rdtDay {
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            line-height: 22px;
            cursor: pointer;
            border-radius: ${props => props.theme.misc.borderRadius};

            &.rdtPast {
              color: ${props => props.theme.colors.etherealGrey};
            }

            &.rdtOld,
            &.rdtNew {
              color: ${props => props.theme.colors.gray};
            }

            &.rdtActive {
              background: rgb(255, 227, 229);
            }

            &.rdtDisabled {
              pointer-events: none;
            }

            &.rdtToday {
              background: ${props => props.theme.colors.primary};
              color: #fff;
            }
          }
        }
      }
    }
  }

  .input-datepicker__calendar-button {
    cursor: pointer;
  }

  &.rdtOpen,
  .input-wrapper:hover {
    .input-datepicker__calendar-button .icon {
      fill: #000;
    }
  }

  .input {
    margin: 0;
    width: 100%;

    .input-datepicker__calendar-button {
      width: 16px;
      height: 16px;
      cursor: pointer;

      .icon {
        fill: #000;
      }

      &:hover .icon {
        fill: #000;
      }
    }
  }
`;
