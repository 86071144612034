import React from 'react';

import { styled } from 'styles';

const PrivacyContent = () => {
  return (
    <StyledPrivacyContent className="privacy">
      <h3 className="privacy__title">Privacy Policy</h3>
      <p className="privacy__description">
        At IPriceRealty, your privacy and the protection of your Personal Data is important to us.
      </p>
      <p className="privacy__description">
        Personal Data means information that can directly or indirectly identify you or other
        individuals (“Personal Data”). This typically includes information such as your surname,
        name, name suffix, date of birth, address, email address, telephone number, date of
        purchase, and financial details provided by you in connection with your purchase. Personal
        Data can also include other information such as your IP address.
      </p>
      <p className="privacy__description">
        The categories of Personal Data that we process depend on how you use our services. We use
        your Personal Data to provide our website services in alignment with your preferences, to
        deal with your requests, to contact you regarding tailored products and services which may
        be of interest to you, or to carry out relevant administrative services. All Personal Data
        is processed in accordance with applicable data protection laws.
      </p>
      <p className="privacy__description">
        We do not disclose your Personal Data to any third party except to our affiliates and to
        data processors that assist us with providing our services. With your consent we use cookies
        for marketing, performance, and statistical purposes.
      </p>
      <p className="privacy__description">
        As our valid customer, we also offer you various choices to control how your Personal Data
        is used.
      </p>
      <h4 className="privacy__small-title">Personal Data We Collect</h4>
      <p className="privacy__description">
        For this purpose (Contact) we process the following Personal Data: Surname, name, company
        email address, telephone or mobile number, passwords, company, country, the reason for
        requesting IPriceRealty access, posts, and other content you submit to our Sites, other
        Personal Data you voluntarily provide to us, information on the handling of your request,
        queries from us, and further information submitted with your request. Please, however,
        refrain from sending us sensitive data in unencrypted emails.
      </p>
      <h4 className="privacy__small-title">How Do We Process Your Personal Data?</h4>
      <p className="privacy__description">
        We process your Personal Data whenever you contact us and respond to your enquiries and
        comments. We process your Personal Data that you enter when you register for an IPriceRealty
        account, or when you subsequently update or amend data in your IPriceRealty account. We
        process Personal Data to provide you with our Services that you request from us, including
        responding to your queries or comments. We look at the Services you buy at our Sites and
        that you have viewed on our Sites.
      </p>
      <h4 className="privacy__small-title">How Long Do We Store Your Personal Data?</h4>
      <p className="privacy__description">
        We will store your Personal Data only until the aforementioned purposes for which we have
        collected or received your Personal Data are fulfilled and once our statutory obligations to
        preserve records have expired.
      </p>
      <h4 className="privacy__small-title">How We Share the Information We Collect</h4>
      <p className="privacy__description">
        We may share or otherwise disclose information we collect from or about you as described
        below or otherwise disclosed to you at the time of collection.
      </p>
      <p className="privacy__description">
        Affiliates. Our entities and affiliates that are not financial institutions or have
        collected information outside of the relationship of providing financial services or
        products may share non-financial information about you with IPriceRealty-affiliated
        companies, such as title insurers, property and casualty insurers, and trust and investment
        advisory companies, or companies in real estate services, such as appraisal companies and
        home warranty companies, to provide related offers, products, and services that may be of
        interest to you or to manage and improve our Services as permitted under applicable law or
        otherwise disclosed to you at the time we collect this information.
      </p>
      <p className="privacy__description">
        Financial Business Partners. In certain situations, we may share information about you to
        nonaffiliated third-party business partners, as permitted by law or with your consent. We
        will provide information to allow you to recognize when a business partner is associated
        with such a transaction or service, and we will share information about you with that
        business partner to the extent related to the transaction or service.
      </p>
      <p className="privacy__description">
        Home Services Partners. We may share certain information, such as name, email address,
        property address, and other information about the property, about you to nonaffiliated
        third-party partners who provide home improvement services, including remodeling, repair,
        maintenance, painting, and moving services that may be of use to you.
      </p>
      <p className="privacy__description">
        Vendors and Service Providers. We may share any information we receive with agents, vendors,
        and service providers retained to perform tasks on our behalf in connection with the
        provision of our Services. We may also provide information we receive to service providers
        that perform marketing services on our behalf, on behalf of our affiliated companies, or to
        financial institutions with whom we or our affiliated companies have joint marketing
        agreements.
      </p>
      <p className="privacy__description">
        Other Lending Entities and Third Parties Needed to Complete Your Transaction. We may share
        certain information we receive about you in connection with your loan or refinancing
        application with credit reporting agencies, underwriters, mortgage companies and lenders,
        insurance agents, homeowners associations, attorneys, bookkeepers and accountants, notaries,
        real estate agents and brokers, contractors, structural pest control operators, financial
        institutions, and other similar entities as necessary to process your application, originate
        and service your loan, or render services to you or the real property or business that is
        the subject of your transaction.
      </p>
      <p className="privacy__description">
        Government Entities and Financial Institutions. We may share certain information we receive
        about you in connection with loans that we issue with federal and state government entities
        or financial institutions that may purchase, insure, or service the loan after funding.
      </p>
      <p className="privacy__description">
        Analytics Partners. We use analytics services such as Google Analytics to collect and
        process certain analytics data. These services may also collect information about your use
        of other websites, apps, and online resources. You can learn about Google’s practices by
        going to www.google.com/policies/privacy/partners, and opt-out of them by downloading the
        Google Analytics opt-out browser add-on, available at tools.google.com/dlpage/gaoptout.
      </p>
      <p className="privacy__description">
        Other Users. The Services may include social networking functionalities that allow you to,
        upon your direction, interact and communicate with other Users. If you choose to use such
        functionalities to share information with other Users, the content you post to the Services
        will be viewable by other users by default. We are not responsible for the other Users’ use
        of available information, including through discussion boards, in messages, and chat areas,
        so you should carefully consider whether and what to post or how you identify yourself on
        the Services.
      </p>
      <p className="privacy__description">
        As Required by Law and Similar Disclosures. We may access, preserve, and disclose
        information about you if we believe doing so is required or appropriate to: (a) comply with
        law enforcement requests and legal process, such as a court order or subpoena; (b) respond
        to your requests; or (c) protect your, our, or others’ rights, property, or safety.
      </p>
      <p className="privacy__description">
        Merger, Sale, or Other Asset Transfers. We may disclose and transfer information about you
        to service providers, advisors, potential transactional partners, or other third parties in
        connection with the consideration, negotiation, or completion of a corporate transaction in
        which we are acquired by or merged with another company or we sell, liquidate, or transfer
        all or a portion of our business or assets.
      </p>
      <p className="privacy__description">
        Consent. We may also disclose information from or about you or your devices with your
        permission.
      </p>
      <h4 className="privacy__small-title">Cookies and Other Tracking Technologies</h4>
      <p className="privacy__description">
        Like most websites and applications, IPriceRealty uses HTML, Flash, and device cookies,
        beacons, clear GIFs or pixels, and other similar tracking technologies to assist us in
        making your online experience more individualized and to improve and to prevent abuse of our
        Website and Service. We may also use trusted third-party services that track this
        information on our behalf.
      </p>
      <p className="privacy__description">
        The purpose of a cookie is to tell the server that you have returned to a specific page
        and/or are using a certain service again in order to personalize your experience. Cookies
        are uniquely assigned to you, and you have the ability to accept or decline cookies. Cookies
        also allow you to be logged into our system automatically. If you choose to decline cookies,
        you may not be able to fully experience the interactive features of IPriceRealty and may
        experience technical problems receiving certain information and accessing certain features
        and services.
      </p>
      <p className="privacy__description">
        We use beacons in conjunction with cookies to understand how Users interact with our Website
        and Services. A beacon is a tiny image file used to monitor your journey around a single
        website or a collection of websites. Beacons are commonly used by websites and advertising
        networks to monitor traffic. Generally, any electronic image, as part of a web page or
        application, including banner ads, can act as a beacon.
      </p>
      <p className="privacy__description">
        An embedded script is a programming code that is designed to collect information about your
        interactions with the Website and Service, such as the links you click on or online areas
        you visited. The code is temporarily downloaded from our server or a third-party server to
        your device.
      </p>
      <h4 className="privacy__small-title">Changes to this Privacy Policy</h4>
      <p className="privacy__description">
        We're constantly trying to improve our Services, so we reserve the right change this Privacy
        Policy periodically. We will post any adjustments to the Privacy Policy on this page, and
        the revised version will be effective as of the last updated date herein. If we materially
        change the ways in which we use or share information from or about you or your devices
        previously collected from you through the Services, we will make reasonable efforts to alert
        you to the changes by sending a notice to the primary email address provided to us and/or by
        placing a notice on our Services. If you use the Services after any changes to the Privacy
        Policy have been posted, that means you agree to all of the changes.
      </p>
      <p className="privacy__description">
        If you have any questions regarding this Privacy Policy or our security practices, e-mail us
        at <a href="mailto:info@ipricerealty.com">info@ipricerealty.com</a>.
      </p>
      <p className="privacy__description">Last update: Dec 7, 2020</p>
    </StyledPrivacyContent>
  );
};

const StyledPrivacyContent = styled.div`
  .privacy {
    &__title {
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 24px;
    }
    &__small-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 12px;
    }
    &__description {
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 24px;
      &:last-of-type {
        margin: 0;
      }
      strong {
        font-weight: 500;
      }
    }
  }
`;

export default PrivacyContent;
