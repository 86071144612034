import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { NoSSR } from 'components';
import FlatRateContext from './context';
import PackageCard from './PackageCard';

const PackageSection = () => {
  const { t } = useTranslation();
  const { packages, packageId, setPackageId } = useContext(FlatRateContext);
  const handlePackageCardSelectClick = id => {
    setPackageId(id);

    setTimeout(() => {
      const propertyInfoSection = document.querySelector('#property-info-block');
      propertyInfoSection?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
  };

  return (
    <StyledPackageSection className="package-section" id="package-section">
      <h3 className="package-section__title">{t('sell_page.step1')}</h3>
      <NoSSR>
        <div className="packages-wrapper">
          {Object.values(packages).map(item => (
            <PackageCard
              {...item}
              key={item.id}
              active={packageId === item.id}
              onSelectClick={() => handlePackageCardSelectClick(item.id)}
            />
          ))}
        </div>
      </NoSSR>
    </StyledPackageSection>
  );
};

const StyledPackageSection = styled.div`
  padding: 32px 0 72px;

  .package-section {
    &__title {
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 32px;
    }
  }
  .packages-wrapper {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 0 32px;
    .package-section {
      &__title {
        font-size: 20px;
        margin: 0 0 62px;
      }
    }
    .packages-wrapper {
      flex-direction: column;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .package-section {
      &__title {
        text-align: center;
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 32px;
      }
    }
  }
`;

export default PackageSection;
