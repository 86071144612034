module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://broker.xchangeplus.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Broker site","short_name":"Broker site","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"fullscreen","icon":"/var/lib/jenkins/workspace/test.ipricerealty.com/sitedata/iprice/assets/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"98eab610fefaac2b646cd59b6f9b8385"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter:400,500,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-LNH9LDD7CJ"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
