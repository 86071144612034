import { fetchWithToken, commonApiUrl } from 'api';
// import { urlEncodeBody } from 'utils';

export async function getOrigin(): Promise<string> {
  const response = await fetchWithToken('/auth/origin');
  const { origin } = await response.json();
  return origin;
}

export async function getServiceContent(
  origin
): Promise<{
  addons: MLSPackageAddon[];
  packages: MLSPackage[];
}> {
  const response = await fetchWithToken(`${commonApiUrl}/service/content/${origin}`);
  const data = await response.json();

  return {
    addons: data.options
      .filter(item => item.active)
      .map(item => ({
        id: item.hash,
        title: item.header,
        description: item.description,
        price: item.price
      })),
    packages: data.packages
      .filter(item => item.active)
      .map(item => ({
        id: item.hash,
        title: item.header,
        fields: item.description.split(';'),
        price: item.price
      }))
  };
}

export async function sendSellprocessData(data: any): Promise<string> {
  const responsePromise = await fetchWithToken('/sell', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  const response = await responsePromise.json();
  return response.sell_process_id;
}
