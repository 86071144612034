import React, { useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { styled } from 'styles';
import useAuthContext from 'hooks/useAuthContext';
import ContactSection from 'views/HomePage/ContactSection';
import TopInfoSection from './TopInfoSection';
import BenefitsSection from './BenefitsSection';
import PackageSection from './PackageSection';
import PropertyInfo from './PropertyInfo';
import UpgradeInfo from './UpgradeInfo';
import PaymentBlock from './PaymentBlock';
import SelectedPackageSection from './SelectedPackageSection';
import FlatRateContext, { FlatRateContextProvider } from './context';
import SubmitResult from './SubmitResult';

const stripeKey = process.env.GATSBY_STRIPE_KEY || '';
const stripeAccount = process.env.GATSBY_STRIPE_ACCOUNT;

const FlatRatePage = () => {
  const { selectedPackage, propertyInfo, showPayment, submitResult } = useContext(FlatRateContext);
  const { profile } = useAuthContext();

  if (submitResult) return <SubmitResult type={submitResult!} />;

  return (
    <>
      <TopInfoSection />
      <StyledFlatRateWrapper className="flat-rate-wrapper">
        <BenefitsSection />
        <PackageSection />

        <div className="info-wrapper">
          <div className="info-container">
            {Boolean(selectedPackage) && <PropertyInfo data={profile} />}
            {propertyInfo && <UpgradeInfo />}
            {showPayment && <PaymentBlock />}
          </div>
          {Boolean(selectedPackage) && <SelectedPackageSection />}
        </div>
      </StyledFlatRateWrapper>
      <ContactSection />
    </>
  );
};

export default () => (
  <Elements stripe={loadStripe(stripeKey, { stripeAccount })}>
    <FlatRateContextProvider>
      <FlatRatePage />
    </FlatRateContextProvider>
  </Elements>
);

const StyledFlatRateWrapper = styled.div`
  max-width: 1057px;
  margin: 0 auto;
  padding: 0 16px;
  .info-wrapper {
    display: grid;
    grid-template-columns: 640px 1fr;
    grid-gap: 0 36px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .info-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
`;
