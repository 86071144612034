/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import * as defaultComponents from './common/components';
import defaultInfo from './common/info.json';

export { default as defaultTheme } from './common/theme.json';

const siteName = process.env.GATSBY_SITE_DATA || 'common';
export const theme = require(`./${siteName}/theme.json`);

export const locales = {
  en: require(`./${siteName}/locales/en/translation.json`)
};

export const info: typeof defaultInfo = require(`./${siteName}/info.json`);

export const Components: typeof defaultComponents = require(`./${siteName}/components`);
