import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
  /**
   * Disable button.
   */
  disabled?: boolean;
  /**
   * Removes background.
   */
  outline?: boolean;
  /**
   * Render as secondary.
   */
  secondary?: boolean;
  /**
   * Render as primary.
   */
  primary?: boolean;
  /**
   * Render without border and background.
   */
  link?: boolean;
  /**
   * Render without border and background.
   */
  simple?: boolean;
  /**
   * Take 100% width of the parent element
   */
  fluid?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  disabled,
  link,
  primary,
  outline,
  secondary,
  simple,
  fluid,
  className,
  children,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const Component = link ? StyledLinkButton : StyledButton;
  return (
    <Component
      disabled={disabled}
      className={clsx(
        'button',
        { disabled, primary, outline, secondary, simple, fluid },
        className
      )}
      {...props}>
      {children}
    </Component>
  );
};

export default React.memo(Button);

const primary = props => props.theme.colors.primary;
const hovered = props => props.theme.colors.hovered;

const StyledButton = styled.button`
  background: #fff;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.borderColor};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  height: 36px;
  padding: 10px 8px;
  border-radius: ${props => props.theme.misc.borderRadius};
  transition: border 200ms, background 200ms, color 200ms, fill 200ms;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &:hover {
    border-color: #afafaf;
  }

  &.primary {
    background: ${primary};
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    fill: #fff;
    border-color: ${primary};
    box-shadow: 0px 4px 5px rgba(121, 77, 255, 0.1);

    &:hover {
      background: ${hovered};
      border-color: ${hovered};
    }
  }

  &.outline {
    background: none;
    box-shadow: none;

    &.primary {
      color: ${props => props.theme.colors.primary};
      fill: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};

      &:hover {
        background: none;
        border-color: ${hovered};
        color: ${hovered};
        fill: ${hovered};
      }
    }
    &.secondary {
      border-color: ${props => props.theme.colors.borderColor};
      &:hover {
        background: none;
        border-color: ${props => props.theme.colors.pastelGray};
      }
    }
  }

  &.simple {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: initial;
    fill: initial;
    height: auto;
    width: auto;
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  &.primary.simple {
    color: ${primary};
    fill: ${primary};
  }

  &.fluid {
    width: 100%;
  }

  &.disabled {
    pointer-events: none;
    background: ${props => props.theme.colors.harborGray};
    border: none;
  }

  &:focus {
    outline: 0;
  }
`;

const StyledLinkButton = styled.button`
  background: none;
  border: none;
  color: ${primary};
  fill: ${primary};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  padding: 0;
  cursor: pointer;
  outline: none;

  &.disabled {
    pointer-events: none;
  }
`;
