import React from 'react';
import { useRifm } from 'rifm';

import { formatCurrency } from 'utils/rifmFormatters';
import Input, { InputProps } from 'components/Input';

const currencyToNumber = (str: string) => (str ? Number(str.replaceAll(/[^0-9.]/g, '')) || 0 : 0);

interface CurrencyInputProps extends Omit<InputProps, 'onChange'> {
  value: number | string;
  onChange: (value: number) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, onChange, ...props }) => {
  const rifm = useRifm({
    accept: /\d/g,
    value: String(value),
    onChange: value => onChange(currencyToNumber(value)),
    format: formatCurrency
  });
  return <Input value={rifm.value} onChange={rifm.onChange} {...props} type="string" />;
};

export default CurrencyInput;
