import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import coverSell from 'assets/images/cover-sell.svg';
import coverSellMob from 'assets/images/cover-sell-mob.svg';
import useLayout from 'hooks/useLayout';

import { styled } from 'styles';

const TopInfoSection = () => {
  const { t } = useTranslation();

  const layout = useLayout();

  const handleViewPlansButtonClick = () => {
    setTimeout(() => {
      const packageSection = document.querySelector('#package-section');
      packageSection?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
  };

  return (
    <StyledTopInfoSection className="top-info-section">
      <div className="top-info-section__image-wrapper">
        {layout === 'desktop' && (
          <img src={coverSell} alt="sell-cover-image" className="top-info-section__cover-image" />
        )}
        {layout === 'tablet' && (
          <img
            src={coverSellMob}
            alt="sell-cover-image"
            className="top-info-section__cover-image"
          />
        )}
      </div>
      <div className="sell-info-block">
        <h1 className="sell-info-block__title">{t('sell_page.title1')}</h1>
        <p className="sell-info-block__description">{t('sell_page.description1')}</p>
        <Button
          className="sell-info-block__button"
          onClick={handleViewPlansButtonClick}
          primary>
          {t('sell_page.button1')}
        </Button>
      </div>
    </StyledTopInfoSection>
  );
};

const StyledTopInfoSection = styled.div`
  height: 496px;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  .top-info-section {
    &__image-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      width: 100%;
      img {
        position: relative;
        right: -170px;
        top: -240px;
      }
    }
  }
  .sell-info-block {
    position: relative;
    z-index: 2;
    max-width: 436px;
    flex: 1;
    margin: 0 0 0 48px;
    &__title {
      font-weight: bold;
      font-size: 40px;
      line-height: 1.2;
      color: black;
      margin: 0 0 32px;
    }
    &__button {
      flex-shrink: 0;
    }
    &__description {
      margin: 0 0 36px;
    }
  }

  @media (min-width: 1680px) {
    .top-info-section {
      &__image-wrapper {
        img {
          right: 170px;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .sell-info-block {
      margin: 0;
      max-width: 399px;
    }
    .top-info-section {
      &__image-wrapper {
        img {
          top: -100px;
          right: -200px;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px 16px 47px;
    height: 565px;
    .sell-info-block {
      max-width: 100%;
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      &__title {
        text-align: center;
        padding: 0 31px;
      }
      &__button {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

export default TopInfoSection;
