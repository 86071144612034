import React, { useState, useRef, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';

import { styled } from 'styles';
import { Icon } from 'components';
import { getAutocompleteSuggestions } from 'api/listings';
import useOutsideClick from 'hooks/useOutsideClick';
import notify from 'notify';
import SearchResults from './SearchAutocompleteResults';

const MIN_CHARACTERS = 2;

export type AutocompleteSubmitValue = { suggestion?: Suggestion; query: string | undefined };

const toTitleCase = phrase => {
  return phrase
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

interface SearchAutocompleteProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onSubmit'> {
  onSubmit?: (value: AutocompleteSubmitValue) => void;
  value?: any;
  before?: React.ReactNode;
}

const SearchAutocomplete: React.FC<SearchAutocompleteProps> = ({
  value: valueProp = '',
  before,
  onSubmit,
  className,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>(valueProp);
  const [groupedSuggestions, setGroupedSuggestions] = useState<GroupedSuggestions>();
  const [open, setOpen] = useState(false);

  const submit = (value: AutocompleteSubmitValue) => {
    if (onSubmit) onSubmit(value);
    setOpen(false);
  };

  const getSuggestions = useCallback(async value => {
    try {
      const suggestions = await getAutocompleteSuggestions(value);
      const isEmpty = Object.values(suggestions).length === 0;
      // return isEmpty ? undefined : autocomplete;
      setGroupedSuggestions(isEmpty ? undefined : suggestions);
    } catch (err) {
      // eslint-disable-next-line no-console
      notify(err.message);
    }
  }, []);

  const [getSuggestionsDebounced] = useDebouncedCallback(getSuggestions, 250, {
    trailing: true
  });

  useOutsideClick(containerRef, () => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    if (value.length >= MIN_CHARACTERS && open) getSuggestionsDebounced(value);
  }, [getSuggestionsDebounced, open, value]);

  const handleChange = e => {
    setValue(e.target.value);
    if (e.target.value.length < MIN_CHARACTERS) setGroupedSuggestions(undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') submit({ query: value || undefined });
  };

  const selectSuggestion = (suggestion: Suggestion) => {
    if (suggestion) {
      const query = toTitleCase(suggestion.name);
      setValue(query);
      submit({ suggestion, query: query || undefined });
    } else {
      setValue('');
      submit({ query: undefined });
    }
  };

  const handleSubmitButtonClick = () => {
    submit({ query: value || undefined });
  };

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <StyledSearchAutocomplete ref={containerRef} className={clsx('search', className)}>
      {before}
      <input
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setOpen(true)}
        {...props}
        data-cy="search_input"
      />
      <button
        onClick={handleSubmitButtonClick}
        className="search__button-search"
        data-cy="search_button">
        <Icon name="search" size={20} />
      </button>
      {open && groupedSuggestions && (
        <SearchResults
          className="search__results scrollbar-custom"
          items={groupedSuggestions}
          onSuggestionClick={selectSuggestion}
          data-cy="autocomplete_results"
        />
      )}
    </StyledSearchAutocomplete>
  );
};

export default SearchAutocomplete;

const StyledSearchAutocomplete = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.borderColor};
  transition: ${props => props.theme.transitions.standart};
  border-right: none;
  padding: 10px 0 10px 6px;
  border-radius: 3px;
  background: #fff;
  z-index: 2;
  position: relative;
  outline: none;
  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.pastelGray};
  }

  .icon-pin-mini {
    flex-shrink: 0;
    fill: #c4c4c4;
    margin: 0 8px;
  }

  input {
    border: none;
    height: 100%;
    width: 100%;
    outline: none;
    padding: 0 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    font-weight: normal;

    &::placeholder {
      color: ${props => props.theme.colors.gray};
    }
  }

  .search__button-search {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: ${props => props.theme.colors.primary};
    transition: border 200ms, background 200ms, color 200ms, fill 200ms;

    border: none;
    fill: #fff;
    border-radius: 0 3px 3px 0;
    margin: -1px -1px -1px 0;
    outline: none;
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.hovered};
      border-color: ${props => props.theme.colors.hovered};
    }

    .icon {
      vertical-align: middle;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }
`;
