import { useEffect, useCallback } from 'react';

const defaultConfig = { condition: true };

const useOutsideClick = (ref, callback, { condition } = defaultConfig) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target)) callback(e);
    },
    [callback, ref]
  );

  useEffect(() => {
    if (!condition) return;

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [condition, handleClick]);
};

export default useOutsideClick;
