import React from 'react';
import FormSection from './FormSection';
import InfoSection from './InfoSection';
import TopInfoSection from './TopInfoSection';

const SellPage = () => {
  return (
    <>
      <TopInfoSection />
      <InfoSection />
      <FormSection />
    </>
  );
};

export default SellPage;
