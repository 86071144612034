import React from 'react';
import clsx from 'clsx';
import { styled } from 'styles';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: boolean;
  info?: boolean;
  merged?: 'top' | 'bottom' | 'both';
  active?: boolean;
  number?: number;
}

const Container: React.FC<ContainerProps> = ({
  className,
  children,
  header,
  info,
  active,
  merged,
  number,
  ...props
}) => {
  return (
    <StyledContainer
      className={clsx(
        'container',
        {
          header,
          info,
          active,
          'merged-top': merged === 'top' || merged === 'both',
          'merged-bottom': merged === 'bottom' || merged === 'both'
        },
        className
      )}
      {...props}>
      {number && <span className="num">{number}</span>}
      {children}
    </StyledContainer>
  );
};

export default React.memo(Container);

const StyledContainer = styled.div`
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dadada;
  border-radius: 5px;
  margin: 0 0 20px;
  font-size: 12px;
  line-height: 16px;

  &.header,
  &.header > * {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &.header .icon {
    vertical-align: middle;
    margin-right: 20px;
  }
  &.info {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background: #fafafa;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 20px;
    }

    & > * {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &.active {
    color: ${props => props.theme.colors.primary};
  }
  &.merged-top {
    border-radius: 5px 5px 0px 0px;
    margin: 0;
  }
  &.merged-bottom {
    border-radius: 0px 0px 5px 5px;
    border-top: 0;
  }

  .num {
    margin: 0 20px 0 0;
    font-weight: 600;
  }

  .edit-btn {
    float: right;
  }
`;
