import React from 'react';

import { styled } from 'styles';

const PrivacyContent = () => {
  return (
    <StyledPrivacyContent className="privacy">
      <h3 className="privacy__title">Privacy Policy</h3>
      <p className="privacy__description">
        Please read these Terms of Service (collectively with Privacy Policy and DMCA Copyright
        Policy, the "Terms of Service") fully and carefully before using www.compass.com (the
        "Site") and the services, features, content or Applications (as defined in Section 14 below)
        offered by Urban Compass, Inc. d/b/a Compass and its affiliates (“we,” “us,” or “our”)
        (together with the Site, the “Services”). These Terms of Service set forth the legally
        binding terms and conditions for your use of the Services.
      </p>
      <h4 className="privacy__small-title">Acceptance of Terms of Service</h4>
      <p className="privacy__description">
        BINDING CONTRACT. BY REGISTERING FOR AND/OR USING THE SERVICES IN ANY MANNER, INCLUDING, BUT
        NOT LIMITED TO, VISITING OR BROWSING THE SITE AND/OR THE APPLICATIONS, YOU AGREE THAT YOU
        HAVE READ AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL OTHER TERMS AND
        CONDITIONS, OPERATING RULES, POLICIES, AND PROCEDURES THAT MAY BE PUBLISHED FROM TIME TO
        TIME THROUGH THE SERVICES BY US OR OTHERWISE PROVIDED TO YOU IN CONNECTION WITH THE
        SERVICES, EACH OF WHICH IS INCORPORATED BY REFERENCE INTO THESE TERMS OF SERVICE. IF YOU ARE
        ACCEPTING THESE TERMS ON BEHALF OF A COMPANY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO
        DO SO.
      </p>
      <h4 className="privacy__small-title">Eligibility</h4>
      <p className="privacy__description">
        <strong>Eligibility Requirements.</strong> You represent and warrant that you are at least
        eighteen (18) years of age and that you have the right, authority, and capacity to enter
        into, perform your obligations under, and abide by these Terms of Service. If you are under
        the age of 18, you may not, under any circumstances or for any reason, use the Services. We
        may, in our sole discretion, refuse to offer the Services to any person or entity and change
        its eligibility criteria at any time. You are solely responsible for ensuring that these
        Terms of Service are in compliance with all laws, rules, and regulations applicable to you.
        The right to access the Services is revoked where use of the Services is prohibited or to
        the extent offering, sale or provision of the Services conflicts with any applicable law,
        rule, or regulation. Further, unless otherwise mutually agreed in writing, the Services are
        offered only for your use and not for the use or benefit of any third party; and in any
        event, each person receiving the benefit of the Services mustagree to and abide by these
        Terms of Service as a condition to our obligations.
      </p>
      <p className="privacy__description">
        {' '}
        <strong>Non-U.S. Users.</strong> The Services are controlled and offered by us from our
        facilities in the United States of America. We make no representations that the Services are
        appropriate or available for use in other locations. Those who access or use the Services
        from other jurisdictions do so at their own volition and are responsible for compliance with
        local law. By providing information in connection with the Services, you consent to the
        transfer of your information to, and storage of your information in, the United States, the
        laws of which may not be as stringent as the laws of the country in which you reside.
      </p>
      <h4 className="privacy__small-title">What We Do</h4>
      <p className="privacy__description">
        Our Services. In addition to the services we offer in person, the Services include online
        platforms on which people or entities that wish (and have the right) to rent, lease, or
        sublet their properties (“Landlords”) or sell their properties (“Sellers”) may propose to us
        property listings (“Listings”), and people who wish to rent, lease, or sublease (“Renters”)
        or buy (“Buyers”) such properties may search for, obtain information regarding, and
        potentially complete transactions with respect to, such properties. We do not own, operate,
        or have any control over the properties or Listings.
      </p>
      <p className="privacy__description">
        Please Be Smart. You understand and agree that we are not a party to any agreement you enter
        into with any other user, whether or not that agreement is provided through the Services,
        and you acknowledge that you are solely responsible for your interactions, correspondence,
        and transactions with other users of the Services, whether on the Site, the Applications, in
        person, or otherwise. Please exercise good judgment and common sense; conduct all necessary,
        appropriate, and prudent inquiries, investigations, research, and due diligence; and take
        all necessary precautions when interacting with others or publicly posting Content. For
        purposes of these Terms of Service, the term “Content” includes, without limitation,
        Listings, information, data, text, inquiries, photographs, videos, virtual tours, audio
        clips, written posts, reviews, feedback, comments, software, scripts, graphics, and
        interactive features generated, provided, or otherwise made accessible on or through the
        Services, as well as, all User Content (as defined in Section 8 below).
      </p>
      <p className="privacy__description">
        Advertising. Some Services may be supported by advertising revenue, and you hereby agree
        that we may display advertising, promotions, sponsored Listings, and the like in connection
        with the Services on, around, and in connection with your User Content.
      </p>
      <h4 className="privacy__small-title">License Grant</h4>
      <p className="privacy__description">
        License. Subject to these Terms of Service, we grant each user of the Services a
        non-exclusive, non-sublicensable, and non-transferable license to access and use the
        Services and access, download, and display locally, all Content therein for your personal,
        non-commercial purposes. Any reproduction, modification, distribution, storage, or other use
        of the Services, or any Content therein for any other purpose, is expressly prohibited
        without prior written permission from us. You shall not sell, license, rent, share, publish,
        or otherwise use or exploit any Content outside the Services for commercial use, in
        connection with the provision of services to a third party, or in any way that violates any
        third party right. Without limiting the foregoing, no real estate broker, salesperson,
        agent, or similar state licensed real estate professional may market or make commercial use
        of the Content in any way, including, without limitation, advertising our property listings,
        copying our Content for commercial use, or contacting our customers or the owners or sellers
        of any properties listed on the Services.
      </p>
      <p className="privacy__description">
        Availability of Content. We do not guarantee that any Content will be made available through
        the Services. We reserve the right, but not the obligation, to (i) remove, edit, or modify
        any Content in our sole discretion, at any time, without notice to you, and for any reason
        (including, but not limited to, upon receipt of claims or allegations from third parties or
        authorities relating to such Content or if we are concerned that you may have violated these
        Terms of Service), or for no reason at all, and (ii) remove or block any Content from the
        Services.
      </p>
    </StyledPrivacyContent>
  );
};

const StyledPrivacyContent = styled.div`
  .privacy {
    &__title {
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 24px;
    }
    &__small-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 12px;
    }
    &__description {
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 24px;
      &:last-of-type {
        margin: 0;
      }
      strong {
        font-weight: 500;
      }
    }
  }
`;

export default PrivacyContent;
