import React from 'react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form/dist/types/form';

import { styled } from 'styles';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  after?: React.ReactNode;
  as?: string | React.ComponentType<any>;
  before?: React.ReactNode;
  error?: FieldError | boolean;
  label?: React.ReactNode;
  ref?: any;
  returnNum?: boolean;
  wrapperProps?: object;
  textareaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  mask?: string; // to use with MaskedInput
}

const Input: React.FC<InputProps> = React.forwardRef(
  (
    {
      after,
      as: InputComponent = 'input',
      before,
      className,
      error,
      id,
      label,
      returnNum,
      type,
      wrapperProps,
      textareaProps,
      onChange,
      ...props
    },
    ref
  ) => {
    const handleChange = e => {
      const onChangeValue = returnNum ? Number(e.target.value) : e;
      if (onChange) onChange(onChangeValue);
    };
    return (
      <StyledInput className={clsx('input', { before, after, error }, className)} {...wrapperProps}>
        {label ? (
          <label htmlFor={id} className="input__label">
            {label}
          </label>
        ) : null}
        <div className="input__component-wrapper">
          <span className="input__component-wrapper__before">{before}</span>
          <InputComponent
            ref={ref}
            id={id}
            type={type}
            onChange={handleChange}
            className="input__component"
            {...props}
            {...textareaProps}
          />
          <span className="input__component-wrapper__after">{after}</span>
        </div>
        {typeof error === 'object' && error?.message && (
          <div className="error-text">{error.message}</div>
        )}
      </StyledInput>
    );
  }
);

export default Input;

const StyledInput = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  width: 176px;

  &.before input {
    padding-left: 16px;
  }

  &.after input {
    padding-right: 28px;
  }

  &.error input,
  &.error textarea {
    border-color: ${props => props.theme.colors.red};
  }

  .input__component-wrapper {
    width: 100%;
    position: relative;

    &__before,
    &__after {
      position: absolute;
      top: 0;
      transform: translateY(50%);
      pointer-events: none;
    }

    &__before {
      left: 8px;
    }

    &__after {
      right: 8px;
    }
  }

  .error-text {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.red};
    margin-top: 4px;
    &:empty {
      display: none;
    }
  }

  input,
  textarea {
    width: 100%;
    border: 1px solid ${props => props.theme.colors.borderColor};
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 0 8px;
    text-overflow: ellipsis;
    outline: none;
  }

  input {
    height: 32px;
  }

  textarea {
    padding: 10px 8px;
  }

  label {
    display: block;
    font-weight: 500;
    margin-bottom: 4px;
  }
`;
