import React, { useContext, useState } from 'react';

import MapContext from './mapContext';
import { CluserPoint } from '.';

interface ClusterProps {
  data: CluserPoint;
}

const Cluster: React.FC<ClusterProps> = ({ data, ...props }) => {
  const {
    clusterPoints,
    map,
    maxClusterZoom,
    supercluster,
    setZoomDisabled,
    setClusterListingsModalItems
  } = useContext(MapContext);
  const [listingsIds, setListingsIds] = useState<string[]>();
  const open = Boolean(listingsIds);
  const size = 30 + (data.properties.point_count / clusterPoints.length) * 20;

  const closeTooltip = () => {
    setListingsIds(undefined);
    setZoomDisabled(false);
  };

  const handleClick = () => {
    if (open) {
      closeTooltip();
    } else {
      const [longitude, latitude] = data.geometry.coordinates;
      try {
        const expansionZoom = supercluster!.getClusterExpansionZoom(data.id);
        if (expansionZoom > maxClusterZoom) {
          const leaves = supercluster!.getLeaves(data.id) as any;
          setClusterListingsModalItems(leaves.map(item => item.properties.data.id));
        } else {
          map!.setZoom(expansionZoom);
          map!.panTo({ lat: latitude, lng: longitude });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <div
        className="cluster-marker"
        style={{
          width: `${size}px`,
          height: `${size}px`
        }}
        onClick={handleClick}
        {...props}>
        {data.properties.point_count}
      </div>
      {/* {listingsIds && <ListingCardMapList ids={listingsIds} onOutsideClick={closeTooltip} />} */}
    </>
  );
};

export default React.memo(Cluster);
