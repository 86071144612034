import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { AuthContext, AuthModalContext } from 'auth';
import { styled } from 'styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FlatRateContext from './context';

type SubmitResultProps = {
  type: 'success' | 'failure';
};

const SubmitResult: React.FC<SubmitResultProps> = ({ type }) => {
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen, setMessage } = useContext(AuthModalContext);
  const [showDetails, setShowDetails] = useState(false);
  const {
    selectedPackage,
    selectedAddons,
    propertyInfo,
    total,
    setSubmitResult,
    sellprocessID
  } = useContext(FlatRateContext);
  const { t } = useTranslation();

  const openSellProcess = async () => {
    navigate(`/sell-process?id=${sellprocessID}`, { state: { propertyInfo } });
  };

  const handleStartClick = () => {
    if (!authorized) {
      setAuthModalOpen(true);
      setMessage(
        `To continue please Sign In or Sign Up for an account with ${t('auth_form.company_name')}`
      );
    } else openSellProcess();
  };

  if (type === 'failure')
    return (
      <StyledSubmitResultFailure>
        <div className="status-icon">
          <Icon name="cross" />
        </div>
        <p className="text">Something went wrong. Please try again</p>
        <Button primary onClick={() => setSubmitResult(undefined)}>
          Go Back
        </Button>
      </StyledSubmitResultFailure>
    );

  return (
    <StyledSubmitResultSuccess>
      <div className="block">
        <span className="icon-circle">
          <Icon name="checkmark" size={20} />
        </span>
        <div className="block__title block__title_small">
          Thank you! Your package is successfully purchased.
        </div>
      </div>
      <div className="block">
        <div className="block__title">
          Flat Fee MLS Package Purchased <span className="price">${total}</span>
        </div>
        <p>{propertyInfo?.address}</p>
        <Button simple onClick={() => setShowDetails(v => !v)}>
          {showDetails ? 'Hide Purchase Details' : 'Show Purchase Details'}
        </Button>
        {showDetails && (
          <div className="details">
            <div className="details__row">
              <span className="details__row__label">Address</span>{' '}
              <div className="details__row__text">{propertyInfo?.address}</div>
            </div>
            <div className="details__row">
              <span className="details__row__label">Name</span>{' '}
              <div className="details__row__text">{propertyInfo?.name}</div>
            </div>
            <div className="details__row">
              <span className="details__row__label">Phone</span>{' '}
              <div className="details__row__text">{propertyInfo?.phone}</div>
            </div>
            <div className="details__row email">
              <span className="details__row__label">Email</span>{' '}
              <div className="details__row__text">{propertyInfo?.email}</div>
            </div>
            <div className="details__row">
              <span className="details__row__label">Package</span>{' '}
              <div className="details__row__text">{selectedPackage?.title}</div>
            </div>
            {selectedAddons.length > 0 && (
              <div className="details__row addons">
                <span className="details__row__label">Add-ons</span>{' '}
                {selectedAddons.map(item => (
                  <div key={item.id} className="details__row__text">
                    {item.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="block">
        <div className="block__title">
          Confirmation of your order has been sent to {propertyInfo?.email}
        </div>
        <p className="block__description">
          Please check your email for order confirmation. To continue, please click Start Listing.
        </p>
        <Button className="start-btn" primary onClick={handleStartClick}>
          Start Listing
        </Button>
      </div>
    </StyledSubmitResultSuccess>
  );
};

export default SubmitResult;

const StyledSubmitResultSuccess = styled.div`
  .block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1004px;
    padding: 28px;
    margin: 24px auto;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    &:first-of-type {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
    &__description {
      font-size: 14px;
      line-height: 24px;
      margin: 0;
    }

    .price {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      float: right;
    }

    .button.simple {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.primary};
      margin-right: auto;
    }

    p {
      margin: 12px 0;
    }
  }

  .icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.primary};
    margin-right: 32px;
    float: left;

    .icon {
      fill: ${props => props.theme.colors.primary};
    }
  }

  .start-btn {
    width: 164px;
    height: 36px;
    margin: 32px 0 0 auto;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  .details {
    margin-top: 16px;

    &__row {
      padding: 20px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      &:not(:first-of-type) {
        border-top: 1px solid #eeeeee;
      }

      &__label {
        font-size: 14px;
        line-height: 18px;
        color: #8b8b8b;
        float: left;
      }

      &__text {
        margin-left: 128px;
      }

      &.addons .details__row__text:not(:first-of-type) {
        margin-top: 12px;
      }

      &.email .details__row__text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .block {
      padding: 12px 20px;
      margin: 12px auto;
      &__title {
        line-height: 32px;
        &_small {
          font-size: 14px;
          line-height: 22px;
          font-weight: normal;
        }
      }
      .icon-circle {
        margin-right: 16px;
      }
      .price {
        font-size: 20px;
        margin-top: 8px;
        float: none;
        display: block;
      }
      .start-btn {
        width: 100%;
      }
    }
  }
`;

const StyledSubmitResultFailure = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  height: 300px;

  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 50%;

    .icon {
      fill: ${props => props.theme.colors.primary};
    }
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 48px;
  }

  .button {
    height: 36px;
    width: 100%;
    max-width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
`;
