import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import { Button, Icon } from 'components';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

interface PackageCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  price: number;
  duration?: string;
  primary?: boolean;
  fields: string[];
  active?: boolean;
  onSelectClick?: () => void;
}

const PackageCard: React.FC<PackageCardProps> = ({
  title,
  price,
  duration,
  primary,
  active,
  fields,
  onSelectClick
}) => {
  const { t } = useTranslation();
  const layout = useLayout();
  const [showMoreFields, setShowMoreFields] = useState(false);

  let fieldsDisplay = fields;
  if ((layout === 'mobile' || layout === 'tablet') && fields && !showMoreFields)
    fieldsDisplay = fields.slice(0, 3);

  return (
    <StyledPackageCard className={clsx('package-card', { 'primary-style': primary, active })}>
      <h4 className="package-card__title">{title}</h4>
      <h5 className="package-card__price">{currencyFormatter.format(price)}</h5>
      <p className="package-card__duration">{duration}</p>
      <ul className="package-card-list">
        {fieldsDisplay.length > 0 && (
          <>
            {fieldsDisplay.map(value => {
              const formattedValue = value.toLowerCase().replace(/\s+/g, '');
              return (
                <li className={clsx('package-card-list__item')} key={formattedValue}>
                  <Icon name="checkmark" />
                  <span className="value">{value}</span>
                </li>
              );
            })}
          </>
        )}
      </ul>
      {(layout === 'mobile' || layout === 'tablet') && fields.length > 3 && (
        <Button
          simple
          onClick={() => setShowMoreFields(v => !v)}
          className={clsx('see-more', { open: showMoreFields })}>
          <Icon name="chevron" />
          {t(showMoreFields ? 'sell_page.see_less' : 'sell_page.see_more')}
        </Button>
      )}
      <Button primary className="package-card__btn-select" onClick={onSelectClick}>
        {t(active ? 'sell_page.selected' : 'sell_page.select')}
      </Button>
    </StyledPackageCard>
  );
};

const StyledPackageCard = styled.div`
  padding: 36px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.cementGray};
  margin-right: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  box-sizing: border-box;
  border-radius: ${props => props.theme.misc.borderRadius};
  border: 1px solid transparent;
  &:last-of-type {
    margin-right: 0;
  }
  &.primary-style {
    background: ${props => props.theme.colors.creamyGray};
  }
  &.active {
    border: 1px solid ${props => props.theme.colors.primary};
  }
  .package-card {
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      margin: 0 0 36px;
      text-align: center;
    }
    &__price {
      font-weight: 600;
      font-size: 48px;
      line-height: 67px;
      margin: 0 0 12px;
    }
    &__duration {
      font-size: 12px;
      line-height: 16px;
      margin: 0 0 40px;
      color: ${props => props.theme.colors.primary};
    }
    &__btn-select {
      font-size: 12px;
      line-height: 14px;
      min-width: 133px;
    }
  }
  .package-card-list {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;
    flex: 1;
    max-width: 270px;
    &__item {
      padding: 0;
      margin: 0 0 16px;
      font-size: 14px;
      line-height: 24px;
      position: relative;
      display: flex;
      .icon {
        margin-top: 4px;
        flex-shrink: 0;
        margin-right: 16px;
        fill: #000;
      }
    }
  }

  .see-more {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.primary};
    fill: ${props => props.theme.colors.primary};
    margin: 0 0 20px;
    .icon {
      margin-right: 4px;
      transition: ${props => props.theme.transitions.standart};
    }
    &.open {
      .icon {
        transform: rotate(0.5turn);
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    margin: 0 0 16px;
    padding: 24px 24px 48px;
    .package-card {
      &__title {
        font-size: 20px;
        line-height: 16px;
        margin: 0 0 12px;
      }
      &__duration {
        color: ${props => props.theme.colors.primary};
        margin: 0 0 16px;
      }
      &__btn-select {
        bottom: 24px;
      }
    }
    .package-card-list {
      margin: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 24px;
  }
`;
export default PackageCard;
