import React from 'react';
import { styled } from 'styles';
import clsx from 'clsx';
import { Link } from 'gatsby';
import useSitedataAssets from 'hooks/useSitedataAssets';
import { useLayout } from 'hooks';
import NoSSR from 'components/NoSSR';

export interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
  to?: string;
  compact?: boolean;
}

const Logo: React.FC<LogoProps> = ({ className, to, compact, ...props }) => {
  const layout = useLayout();
  const { logo, logoSmall } = useSitedataAssets();

  if (!logo || !logoSmall) return null;

  const src = layout === 'mobile' || compact ? logoSmall : logo;

  return (
    <NoSSR>
      <StyledLogo
        className={clsx('logo', { compact: layout === 'mobile' || compact }, className)}
        {...props}>
        {to ? (
          <Link to={to} className="logo__link" data-cy="logo_link">
            <img src={src} alt="logo" />
          </Link>
        ) : (
          <img src={src} alt="logo" />
        )}
      </StyledLogo>
    </NoSSR>
  );
};

export default Logo;

const StyledLogo = styled.div`
  display: inline-block;
  font-weight: bold;
  width: 200px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-right: auto;
  flex-shrink: 0;

  &.compact {
    width: 24px;
    height: 24px;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  img {
    width: 100%;
  }
`;
