import React from 'react';

import { styled } from 'styles';

const TermsContent = () => {
  return (
    <StyledTermsContent className="terms">
      <h3 className="terms__title">Terms and Conditions</h3>
      <p className="terms__description">
        Through www.ipricerealty.com (“Website”), (“IPriceRealty”, “we”, “us”, or “our”) provides an
        online service for homeowners to list their residential real estate on the Website and, if
        selected by the homeowner, through the applicable real estate multiple listing service
        (“MLS”), and prospective buyers and tenants to find and purchase or lease, respectively,
        such real estate (collectively, the “Service”).
      </p>
      <p className="terms__description">
        Please read the following Terms of Use (“Terms”) that contain important information about
        your legal rights, obligations, and remedies as they pertain to your access to and use of
        the Service. By accessing or using the Service, you agree to comply with and be bound by
        these Terms, our Privacy Policy (which is hereby incorporated into these Terms by reference)
        and, as applicable, our Exclusive Buyer/Renter Brokerage Agreement (“Buyer Agreement”), and,
        if an Owner elects to have IPriceRealty list their Property on the MLS Service, then our
        Listing Agreement, Disclosures & Disclaimers (“Listing Agreement”).
      </p>
      <p className="terms__description">
        These Terms are a binding agreement between you (“you” or “your”) and IPriceRealty. For
        purposes of clarity, references to “you” in these Terms apply to any and all users or
        visitors to the Website and/or Service. You and IPriceRealty are the only parties to these
        Terms and may be referred to in the singular as a “Party” or in the plural as “Parties”.
        These Terms govern your access to and use of the Website and Services. You also may be
        subject to additional terms and conditions set forth in the Buyer Agreement and Listing
        Agreement that may apply when you list, purchase, or rent, as applicable, Property through
        the Service.
      </p>
      <p className="terms__description">
        IPriceRealty may, at its own discretion, and without advance notice, modify these Terms, or
        change, suspend, discontinue, impose limits or restrict access to any aspect of the Service
        at any time. If you disagree with the modifications, your only recourse is to discontinue
        use of the Service in its entirety. By continuing to access or use the Service, you are
        indicating you agree to be bound by the modified Terms.
      </p>
      <h4 className="terms__small-title">DEFINITIONS</h4>
      <p className="terms__description">
        For purposes of these Terms, the following terms shall have the meanings set forth below:
        “Buyer” means a person who uses the Service to find and purchase a Property. “Content” means
        all materials, excluding Personal Information, but otherwise including, without limitation,
        information, data, text, design elements, graphics, images, audio, video, and other content,
        contained in or delivered via the Website or otherwise made available by IPriceRealty in
        connection with the Service.“Documentation” means the documentation provided to Users
        electronically via the Website, which explains how to access and use the Service.
      </p>
      <p className="terms__description">
        “Intellectual Property Rights" means all legally recognized forms of intellectual Property
        and rights therein, irrespective of whether such rights are based upon common law, statute,
        regulation, ordinance, agreement, contract, treaty, convention or otherwise, and to the full
        extent such rights may arise or be recognized under any law, rule or regulation, whether
        federal, state, local or foreign.
      </p>
      <p className="terms__description">
        “Owner” means a person who uses the Service to list a Property for sale or rent.
      </p>
      <p className="terms__description">
        “Payment Service” means any and all payment processing service used or provided by
        IPriceRealty’s third party payment processor(s) in connection with your use of the Service.
      </p>
      <p className="terms__description">
        “Personal Information" means any information that does or can identify a specific individual
        or by or from which a specific individual may be identified, contacted or located, such as
        the individual's name, address, social security number, etc., and any other information
        relating to an identified or identifiable individual. “Personal Information” includes
        "nonpublic personal information," as defined under the Gramm-Leach-Bliley Act (15 U.S.C. §
        6801 et seq.), "protected health information" as defined under the Health and Insurance
        Portability and Accountability Act of 1996 (42 U.S.C. § 1320d), and "Personal Data" as that
        term is defined in EU Data Protection Directive (Directive 95/46/EEC) and its successor, the
        General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679) on the protection of
        individuals with regard to processing of personal data and the free movement of such data,
        and all rules and regulations issued under any of the foregoing.
      </p>
      <p className="terms__description">
        “Process" means to perform any operation or set of operations on any data, information,
        material, work, expression or other Content, including to: collect, capture, process,
        record, receive, input, upload, download, recover, reproduce, store, organize, combine, log,
        catalog, cross-reference, manage, maintain, calculate, copy, adapt, alter, transform,
        translate, prepare derivative works, process, retrieve, output, use, disseminate, transmit,
        submit, post, transfer, display, provide, delete, block, or erase. "Processing" and
        "Processed" shall have correlative meanings.
      </p>
      <p className="terms__description">
        “Property” means any residential real property listed and made available for purchase or
        rent through the Service.
      </p>
      <p className="terms__description">
        “Renter” means a person who uses the Service to find and rent a Property.
      </p>
      <p className="terms__description">
        “Technological Safeguard” means any code, device feature or functionality which has been
        intentionally provided by or on behalf of IPriceRealty, and which is, or may be at any time
        lawfully invoked under the unilateral control of IPriceRealty, at its sole and exclusive
        discretion, to monitor, detect, document, prevent, limit, deny, interrupt or terminate
        unauthorized, improper or illegal access or use of the Website and/or the Documentation.
        Technological Safeguards may include, but are not limited to, cookies, keys, locks,
        backdoors, drop-dead devices and revocation or disablement of passwords and/or User Accounts
        (as defined below).
      </p>
      <p className="terms__description">
        “User” means any and all visitors to the Website, including Buyers, Owners, and Renters, as
        applicable.
      </p>
      <p className="terms__description">
        “User Data” means any information or data, including, without limitation, any Personal
        Information, which a User, at any time uploads, enters, inputs, sends, transmits or
        otherwise makes available for Processing through or using the Service. User Data does not
        include, and expressly excludes, any code, information, Content or data owned by
        IPriceRealty or any third-party licensor of IPriceRealty.
      </p>
      <h4 className="terms__small-title">USE OF THE SERVICE</h4>
      <p className="terms__description">
        Limited License. Subject to and expressly conditioned on your compliance with these Terms,
        including timely payment of all amounts owed, IPriceRealty grants to you, during the
        applicable term, a personal, non-transferable, revocable, non-sublicensable, non-exclusive,
        worldwide right and license (“License”) to (i) access and use the Service and the Website;
        and (ii) access and view the Documentation via the Website. Other than access and use under
        the License, any other access or use is prohibited. Any and all rights owned or held by
        IPriceRealty and/or our third party licensors not expressly granted to you under this
        License, including all Intellectual Property Rights relating thereto, are reserved and shall
        remain owned solely and exclusively in their entirety by IPriceRealty, its successors,
        assigns, and its third party licensors, if any.
      </p>
      <p className="terms__description">
        Laws, Rules and Regulations. You agree that you are responsible for, and will abide by, all
        laws, rules and regulations applicable to your access and use of the Service, including any
        transactions entered into through the Service. Owners further agree that you are responsible
        for, and will abide by, all laws, rules, ordinances, and regulations applicable to the
        listing of your Property and all related conduct, including, but not limited to, those
        relating to taxes, credit cards, data and privacy, permits or license requirements, zoning
        ordinances, safety compliance and compliance with all anti-discrimination and fair housing
        laws, as applicable. There may be circumstances where we are legally obligated (as we may
        determine in our sole discretion) to share information, including Personal Information, you
        provide through the Service or otherwise in order to comply with requests from governmental
        bodies in relation to investigations, litigation, or administrative proceedings, but, unless
        restricted by applicable law, we will inform you of such request before sharing the
        information.
      </p>
      <p className="terms__description">
        Network Transmissions. You understand that the technical processing and transmission of the
        Website, your Content and your User Data may involve (i) transmissions over various
        networks; and/or (ii) changes to conform and adapt to technical requirements of connecting
        networks or devices.
      </p>
      <p className="terms__description">
        User Data. You agree that, with respect to other Users' User Data that you obtain directly
        or indirectly from or through the Service or through any Service-related communication,
        transaction or software, you may not use any such information for any unlawful purpose or
        with any unlawful intent.
      </p>
      <p className="terms__description">
        Translations and Maps. We cannot guarantee the accuracy or quality of any User-contributed
        Content, which is translated for display on the Website, and you are solely responsible for
        review, verification and accuracy of such translation. Maps that are provided by Google are
        subject to the Google Maps terms and conditions located at:{' '}
        <a
          href="http://www.google.com/intl/en_us/help/terms_maps.html"
          target="_blank"
          rel="noreferrer">
          http://www.google.com/intl/en_us/help/terms_maps.html
        </a>
        .
      </p>
      <p className="terms__description">
        International Use. Recognizing the global nature of the Internet, you agree to comply with
        all local rules regarding online conduct, Content, and User Data. By way of illustration and
        not limitation, you agree to comply with all applicable laws and regulations restricting or
        otherwise pertaining to the use, transmission, display, exporting, or importing of data,
        products, services, and/or technical information. We reserve the right, at any time and in
        our sole discretion, to limit the availability and/or use of the Service to any person,
        geographic area, or jurisdiction.
      </p>
      <p className="terms__description">
        Warranty Disclaimer. Neither IPriceRealty nor its licensors or suppliers makes any
        representations or warranties concerning any content contained in or accessed through the
        Services, and we will not be responsible or liable for the accuracy, copyright compliance,
        legality, or decency of material contained in or accessed through the Services. We (and our
        licensors and suppliers) make no representations or warranties regarding suggestions or
        recommendations of services or products offered or purchased through the Services. Products
        and services purchased or offered (whether or not following such recommendations and
        suggestions) through the Services are provided "AS IS" and without any warranty of any kind
        from IPriceRealty or others (unless, with respect to such others only, provided expressly
        and unambiguously in writing by a designated third party for a specific product).
      </p>
      <p className="terms__description">
        THE SERVICES AND CONTENT ARE PROVIDED BY IPRICEREALTY (AND ITS LICENSORS AND SUPPLIERS) ON
        AN "AS-IS" BASIS, WITHOUT WARRANTIES OR ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
        WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME
        STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
        LIMITATIONS MAY NOT APPLY TO YOU.
      </p>
      <p className="terms__description">
        Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
        CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT,
        STRICT LIABILITY, OR OTHERWISE) SHALL IPRICEREALTY (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE
        TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
        DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE,
        ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE
        AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO
        IPRICEREALTY IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS
        APPLICABLE CLAIM, OR (III) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT
        ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS
        MAY NOT APPLY TO YOU.
      </p>
      <p className="terms__description">
        Indemnity. To the fullest extent allowed by applicable law, you agree to indemnify and hold
        IPriceRealty, its affiliates, officers, agents, employees, and partners harmless from and
        against any and all claims, liabilities, damages (actual and consequential), losses and
        expenses (including attorneys' fees) arising from or in any way related to any third party
        claims relating to (a) your use of the Services (including any actions taken by a third
        party using your account), and (b) your violation of these Terms. In the event of any claim,
        suit, or action ("Claim"), we will attempt to provide notice of the Claim to the contact
        information we have for your account (provided that failure to deliver such notice shall not
        eliminate or reduce your indemnification obligations hereunder).
      </p>
      <p className="terms__description">
        Equal Housing Opportunity. We encourage and support an affirmative advertising and marketing
        program in which there are no barriers to obtaining housing because of race, color,
        religion, sex, handicap, familial status, or national origin.
      </p>
      <p className="terms__description">Last updated: Dec 7, 2020</p>
    </StyledTermsContent>
  );
};

const StyledTermsContent = styled.div`
  .terms {
    &__title {
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 24px;
    }
    &__small-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 12px;
    }
    &__description {
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 24px;
      &:last-of-type {
        margin: 0;
      }
      strong {
        font-weight: 500;
      }
    }
  }
`;

export default TermsContent;
