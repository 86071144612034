import React, { useState, useCallback } from 'react';

import { LOCAL_STORAGE } from 'consts';
import { getStorageObject, setStorageObject, clearStorage } from 'utils/localStorage';
import { login, loginGoogle, logout, LoginPayload, loginFacebook } from './authRequests';

const { AUTH_STORAGE_KEY, PROFILE_STORAGE_KEY, SAVED_ITEMS_STORAGE_KEY } = LOCAL_STORAGE;

interface AuthContextValues {
  authorized: boolean;
  authData?: AuthData;
  profile?: ProfileData;
  savedItems?: SavedItemsData;
  setAuthData: (value: AuthData) => void;
  setProfile: (value: ProfileData) => void;
  setSavedItems: (value: SavedItemsData) => void;
  login: (data: LoginPayload) => Promise<void>;
  loginGoogle: (token: string) => Promise<void>;
  loginFacebook: (token: string) => Promise<void>;
  loginManually: (authData: LoginData) => void;
  logout: () => Promise<void>;
  logoutManually: () => void;
}

const AuthContext = React.createContext({} as AuthContextValues);

export const AuthContextWrapper = ({ children }) => {
  const [authData, _setAuthData] = useState(getStorageObject(AUTH_STORAGE_KEY));
  const [profile, _setProfile] = useState(getStorageObject(PROFILE_STORAGE_KEY));
  const [savedItems, _setSavedItems] = useState(getStorageObject(SAVED_ITEMS_STORAGE_KEY));
  const [authorized, setAuthorized] = useState(Boolean(authData));

  const setAuthData = (value: AuthData) => {
    setStorageObject(AUTH_STORAGE_KEY, value);
    _setAuthData(value);
  };

  const setProfile = (value: ProfileData) => {
    setStorageObject(PROFILE_STORAGE_KEY, value);
    _setProfile(value);
  };

  const setSavedItems = (value: SavedItemsData) => {
    setStorageObject(SAVED_ITEMS_STORAGE_KEY, value);
    _setSavedItems(value);
  };

  const setAuthDataStorage = (data: LoginData) => {
    setAuthData(data.authData);
    setProfile(data.profile);
    setSavedItems(data.savedItems);
  };

  const loginCallback = useCallback(async data => {
    const response = await login(data);
    setAuthDataStorage(response);
    setAuthorized(true);
  }, []);

  const loginGoogleCallback = useCallback(async data => {
    const response = await loginGoogle(data);
    setAuthDataStorage(response);
    setAuthorized(true);
  }, []);

  const loginFacebookCallback = useCallback(async data => {
    const response = await loginFacebook(data);
    setAuthDataStorage(response);
    setAuthorized(true);
  }, []);

  const logoutCallback = useCallback(async () => {
    await logout();
    clearStorage();
    setAuthorized(false);
  }, []);

  const loginManually = (authData: LoginData) => {
    setAuthDataStorage(authData);
    setAuthorized(true);
  };

  const logoutManually = () => {
    clearStorage();
    setAuthorized(false);
  };

  const values: AuthContextValues = {
    authorized,
    authData,
    setAuthData,
    profile,
    setProfile,
    savedItems,
    setSavedItems,
    login: loginCallback,
    loginGoogle: loginGoogleCallback,
    loginFacebook: loginFacebookCallback,
    loginManually,
    logout: logoutCallback,
    logoutManually
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContext;
