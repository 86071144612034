import useAuthContext from './useAuthContext';

const windowSSR: Window | undefined = typeof window !== 'undefined' ? window : undefined;

export default function useAuthLink() {
  const { authorized } = useAuthContext();

  if (!authorized) {
    if (!windowSSR) return;
    const url = new URL(windowSSR.location.href);
    url.searchParams.append('auth', '1');
    return url.pathname + url.search;
  }
  return null;
}
