/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */

import { LOCAL_STORAGE } from 'consts';

const {
  VERSION_STORAGE_KEY,
  AUTH_STORAGE_KEY,
  PROFILE_STORAGE_KEY,
  SAVED_ITEMS_STORAGE_KEY
} = LOCAL_STORAGE;

/**
 * Increment LOCAL_STORAGE_VERSION below in case structure of local storage objects was changed
 */
const STORAGE_VERSION = '1';

// eslint-disable-next-line prefer-destructuring
const localStorage: Storage | undefined =
  typeof window !== 'undefined' ? window.localStorage : undefined;

export const clearStorage = () => {
  localStorage?.removeItem(AUTH_STORAGE_KEY);
  localStorage?.removeItem(PROFILE_STORAGE_KEY);
  localStorage?.removeItem(SAVED_ITEMS_STORAGE_KEY);
  localStorage?.removeItem(VERSION_STORAGE_KEY);
};

const checkVersionMatch = (key?: string) => {
  if (typeof localStorage === 'undefined') return false;
  const version = localStorage?.getItem(VERSION_STORAGE_KEY);
  if (version !== STORAGE_VERSION) {
    if (key) localStorage?.removeItem(key);
    else clearStorage();
    return false;
  }
  return true;
};

const saveVersion = () => {
  try {
    localStorage?.setItem(VERSION_STORAGE_KEY, STORAGE_VERSION);
  } catch (error) {
    console.error(error);
  }
};

export const setStorageObject = (key: string, value: object) => {
  checkVersionMatch(key);
  try {
    localStorage?.setItem(key, JSON.stringify(value));
    saveVersion();
  } catch (error) {
    console.error(error);
  }
};

export const getStorageObject = (key: string): any | undefined => {
  if (!checkVersionMatch(key)) return undefined;
  try {
    const object = localStorage?.getItem(key);
    if (!object) return;
    return JSON.parse(object);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

type CompareStorage = {
  prev: string;
  value: any;
};

export const getPrevCompareStorage = (key: string, prev: string): CompareStorage | undefined => {
  if (!checkVersionMatch()) return undefined;

  const storage = localStorage?.getItem(key);

  if (storage) {
    const parsedStorage: CompareStorage = JSON.parse(storage);
    if (parsedStorage.prev === prev) {
      return parsedStorage;
    }
  }

  return undefined;
};

export const setNewCompareStorage = (key: string, prev: string, value: any) => {
  checkVersionMatch();
  localStorage?.setItem(key, JSON.stringify({ prev, value }));
};
