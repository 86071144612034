import React, { useCallback, useEffect, useState } from 'react';
import notify from 'notify';
import { getOrigin as getOriginRequest, getServiceContent } from './api';
// import packages from './packages.json';
// import addons from './addons.json';

interface FlatRateContextValues {
  packages: MLSPackage[];
  addons: MLSPackageAddon[];
  packageId: string | null;
  setPackageId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedPackage: MLSPackage | undefined;
  propertyInfo: PropertyInfo | null;
  setPropertyInfo: React.Dispatch<React.SetStateAction<PropertyInfo | null>>;
  selectedAddonsIds: number[];
  setSelectedAddonsIds: React.Dispatch<React.SetStateAction<number[]>>;
  selectedAddons: MLSPackageAddon[];
  showPayment: boolean;
  setShowPayment: React.Dispatch<React.SetStateAction<boolean>>;
  disablePayment: boolean;
  setDisablePayment: React.Dispatch<React.SetStateAction<boolean>>;
  total: number | null;
  origin?: string;
  submitResult?: 'success' | 'failure';
  setSubmitResult: React.Dispatch<React.SetStateAction<'success' | 'failure' | undefined>>;
  sellprocessID: string | undefined;
  setSellprocessID: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FlatRateContext = React.createContext({} as FlatRateContextValues);

export const FlatRateContextProvider = ({ children }) => {
  const [origin, setOrigin] = useState<string>();
  const [packages, setPackages] = useState<MLSPackage[]>([]);
  const [addons, setAddons] = useState<MLSPackageAddon[]>([]);
  const [packageId, setPackageId] = useState<string | null>(null);
  const [propertyInfo, setPropertyInfo] = useState<PropertyInfo | null>(null);
  const [showPayment, setShowPayment] = useState(false);
  const [disablePayment, setDisablePayment] = useState(false);
  const [selectedAddonsIds, setSelectedAddonsIds] = useState<number[]>([]);
  const [submitResult, setSubmitResult] = useState<'success' | 'failure'>();
  const [sellprocessID, setSellprocessID] = useState<string | undefined>();

  const selectedPackage = packages.find(item => item.id === packageId);
  const selectedAddons: MLSPackageAddon[] = addons.filter(item =>
    selectedAddonsIds.includes(item.id)
  );
  let total: number | null = null;
  if (selectedPackage) total = selectedPackage.price;
  if (selectedAddons.length) {
    selectedAddons.forEach(item => {
      total! += item.price;
    });
  }

  const getOrigin = useCallback(async () => {
    try {
      const origin = await getOriginRequest();
      setOrigin(origin);
    } catch (err) {
      notify(err.message);
    }
  }, []);

  const fetchContent = useCallback(async origin => {
    try {
      const { packages, addons } = await getServiceContent(origin);
      setPackages(packages);
      setAddons(addons);
    } catch (err) {
      notify(err.message);
    }
  }, []);

  useEffect(() => {
    if (!origin) getOrigin();
    else fetchContent(origin);
  }, [origin, fetchContent, getOrigin]);

  const values: FlatRateContextValues = {
    packages,
    addons,
    packageId,
    setPackageId,
    selectedPackage,
    propertyInfo,
    setPropertyInfo,
    selectedAddons,
    selectedAddonsIds,
    setSelectedAddonsIds,
    showPayment,
    setShowPayment,
    disablePayment,
    setDisablePayment,
    total,
    origin,
    submitResult,
    setSubmitResult,
    sellprocessID,
    setSellprocessID
  };

  return <FlatRateContext.Provider value={values}>{children}</FlatRateContext.Provider>;
};

export default FlatRateContext;
