import React from 'react';
import toaster from 'toasted-notes';
import Notification from 'components/Notification';

export default function notify(content, settings?) {
  toaster.notify(props => <Notification {...props}>{content}</Notification>, {
    duration: 5000,
    position: 'bottom',
    ...settings
  });
}
