import { useRef, useEffect, DependencyList } from 'react';

export default function useDidUpdateEffect(fn, inputs: DependencyList | undefined) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
