import React, { useRef } from 'react';
import { useOutsideClick } from 'hooks';

interface ClickOutsideWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  onOutsideClick?: () => void;
}

const ClickOutsideWrapper: React.FC<ClickOutsideWrapperProps> = ({
  children,
  onOutsideClick,
  ...props
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, onOutsideClick);

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};

export default React.memo(ClickOutsideWrapper);
