import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import useAuthContext from 'hooks/useAuthContext';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { authorized } = useAuthContext();
  if (typeof window === 'undefined') return null;
  // do we still need auth=1 check? Context is used for auth modal
  if (!authorized && location.pathname !== '/?auth=1') {
    navigate('/');
    return null;
  }
  return <>{children}</>;
};
export default PrivateRoute;
