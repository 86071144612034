import React from 'react';
import { useForm } from 'react-hook-form';

import { useAuthContext } from 'hooks';
import Input from 'components/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';

interface SignInFormProps {
  onSuccess: () => void;
  onForgotPassowordClick: () => void;
}

const SignInForm: React.FC<SignInFormProps> = ({ onSuccess, onForgotPassowordClick }) => {
  const { login } = useAuthContext();
  const { register, errors, setError, clearErrors, handleSubmit } = useForm();
  const errorText = Object.values(errors)[0]?.message;

  const submit = async values => {
    try {
      await login(values);
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setError('general', { type: 'manual', message: 'Invalid username or password' });
    }
  };

  const clearGeneralError = () => {
    if (errors.general) clearErrors('general');
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {errorText && (
        <div className="error">
          <Icon name="warning" />
          {errorText}
        </div>
      )}
      <Input
        name="email"
        ref={register({
          required: 'Required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        })}
        onChange={clearGeneralError}
        error={Boolean(errors.email)}
        placeholder="Email"
      />
      <Input
        name="password"
        ref={register({
          required: 'Required'
        })}
        onChange={clearGeneralError}
        error={Boolean(errors.password)}
        placeholder="Password"
        type="password"
      />
      <Button primary type="submit" className="submit">
        Sign In
      </Button>
      <Button simple primary type="button" className="forgot-pass" onClick={onForgotPassowordClick}>
        Forgot Password
      </Button>
    </form>
  );
};

export default SignInForm;
