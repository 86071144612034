import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from 'styles';
import property from 'assets/images/property-for-sale.jpg';
import useLayout from 'hooks/useLayout';

const BenefitsSection = () => {
  const { t } = useTranslation();
  const layout = useLayout();
  return (
    <StyledBenefitsSection className="benefits-section">
      <div className="benefits-section-info-wrapper">
        {layout === 'mobile' && (
          <div className="benefits-section-info">
            <img src={property} alt="property-for-sale" className="benefits-section-info__img" />
          </div>
        )}
        <div className="benefits-section-info">
          <h3 className="benefits-section__title">{t('sell_page.title2')}</h3>
          <ol className="benefits-list">
            <li className="benefits-list__item">{t('sell_page.point1')}</li>
            <li className="benefits-list__item">{t('sell_page.point2')}</li>
            {t('sell_page.point3') && (
              <li className="benefits-list__item">{t('sell_page.point3')}</li>
            )}
          </ol>
        </div>
        {layout === 'desktop' && (
          <div className="benefits-section-info property-image">
            <img src={property} alt="property-for-sale" className="benefits-section-info__img" />
          </div>
        )}
      </div>
    </StyledBenefitsSection>
  );
};

const StyledBenefitsSection = styled.div`
  padding: 32px 0 78px;
  .benefits-section-info-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .benefits-section {
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 32px;
    }
  }

  .benefits-section-info {
    &__img {
      object-fit: cover;
      width: 416px;
    }
    &.property-image {
      display: flex;
      justify-content: flex-end;
    }
  }
  .benefits-list {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin: 0;
    padding-left: 36px;
    &__item {
      counter-increment: my-awesome-counter;
      position: relative;
      margin: 0 0 20px;
      font-size: 16px;
      line-height: 16px;
      &::before {
        content: counter(my-awesome-counter);
        color: white;
        background: black;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        position: absolute;
        top: 3px;
        left: -36px;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__item-header {
      display: block;
      margin: 0 0 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 16px 0 20px;
    .benefits-section-info-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 32px 0 55px;
    .benefits-section {
      &__title {
        margin: 0 0 16px;
      }
    }
    .benefits-section-info {
      &__img {
        width: 100%;
      }
    }
    .benefits-section-info-wrapper {
      grid-gap: 24px 0;
    }
  }
`;

export default BenefitsSection;
