import { ReactComponent as AddressSVG } from './address.svg';
import { ReactComponent as ArrowShareSVG } from './arrow-share.svg';
import { ReactComponent as ArrowSimpleSVG } from './arrow-simple.svg';
import { ReactComponent as BurgerMenuSVG } from './burger-menu.svg';
import { ReactComponent as CalendarSVG } from './calendar.svg';
import { ReactComponent as CallSVG } from './call.svg';
import { ReactComponent as CameraSVG } from './camera.svg';
import { ReactComponent as CheckSVG } from './check.svg';
import { ReactComponent as CheckmarkSVG } from './checkmark.svg';
import { ReactComponent as LogoMiniSVG } from './logo-mini.svg';
import { ReactComponent as ChevronSVG } from './chevron.svg';
import { ReactComponent as ContactUsSVG } from './contact-us.svg';
import { ReactComponent as CrossSVG } from './cross.svg';
import { ReactComponent as FileSVG } from './file.svg';
import { ReactComponent as FullViewSVG } from './full-view.svg';
import { ReactComponent as HeartSVG } from './heart.svg';
import { ReactComponent as HeartFilledSVG } from './heart-filled.svg';
import { ReactComponent as LinkSVG } from './link.svg';
import { ReactComponent as ListSVG } from './list.svg';
import { ReactComponent as MailSVG } from './mail.svg';
import { ReactComponent as MapViewSVG } from './map-view.svg';
import { ReactComponent as MiniXSVG } from './mini-x.svg';
import { ReactComponent as MinusSVG } from './minus.svg';
import { ReactComponent as PeopleGroupSVG } from './people-group.svg';
import { ReactComponent as PenSVG } from './pen.svg';
import { ReactComponent as PinMiniSVG } from './pin-mini.svg';
import { ReactComponent as PinSVG } from './pin.svg';
import { ReactComponent as PlusMiniSVG } from './plus-mini.svg';
import { ReactComponent as PlusSimpleSVG } from './plus-simple.svg';
import { ReactComponent as RocketSVG } from './rocket.svg';
import { ReactComponent as RocketSmallSVG } from './rocket-small.svg';
import { ReactComponent as SearchSVG } from './search.svg';
import { ReactComponent as SocialFacebookSVG } from './social-facebook.svg';
import { ReactComponent as SocialGoogleSVG } from './social-google.svg';
import { ReactComponent as SocialPinterestSVG } from './social-pinterest.svg';
import { ReactComponent as SocialTwitterSVG } from './social-twitter.svg';
import { ReactComponent as StreetViewSVG } from './street-view.svg';
import { ReactComponent as SuccessCircleViewSVG } from './success-circle.svg';
import { ReactComponent as SuggestionIdSVG } from './suggestion-id.svg';
import { ReactComponent as SuggestionPlacesSVG } from './suggestion-place.svg';
import { ReactComponent as SuggestionSchollSVG } from './suggestion-school.svg';
import { ReactComponent as TriangleSVG } from './triangle.svg';
import { ReactComponent as WarningSVG } from './warning.svg';
import { ReactComponent as RemoveSavedSVG } from './remove.svg';
import { ReactComponent as SearchSavedSVG } from './search-saved.svg';
import { ReactComponent as VisaSVG } from './visa.svg';
import { ReactComponent as HandSVG } from './hand.svg';
import { ReactComponent as HappySVG } from './happy.svg';
import { ReactComponent as SadSVG } from './sad.svg';
import { ReactComponent as ErrorCircleSVG } from './error-circle.svg';
import { ReactComponent as VerifiedHouseSVG } from './verified-house.svg';
import { ReactComponent as RealEstate1SVG } from './real-estate1.svg';
import { ReactComponent as TooltipTriggerSVG } from './tooltip-trigger.svg';

const icons = {
  'arrow-share': ArrowShareSVG,
  'arrow-simple': ArrowSimpleSVG,
  'burger-menu': BurgerMenuSVG,
  'contact-us': ContactUsSVG,
  'full-view': FullViewSVG,
  'map-view': MapViewSVG,
  'mini-x': MiniXSVG,
  'people-group': PeopleGroupSVG,
  'pin-mini': PinMiniSVG,
  'plus-mini': PlusMiniSVG,
  'plus-simple': PlusSimpleSVG,
  'social-facebook': SocialFacebookSVG,
  'social-google': SocialGoogleSVG,
  'social-pinterest': SocialPinterestSVG,
  'social-twitter': SocialTwitterSVG,
  'street-view': StreetViewSVG,
  'success-circle': SuccessCircleViewSVG,
  'suggestion-id': SuggestionIdSVG,
  'suggestion-place': SuggestionPlacesSVG,
  'suggestion-school': SuggestionSchollSVG,
  'search-saved': SearchSavedSVG,
  'heart-filled': HeartFilledSVG,
  'logo-mini': LogoMiniSVG,
  'error-circle': ErrorCircleSVG,
  'rocket-small': RocketSmallSVG,
  'verified-house': VerifiedHouseSVG,
  'real-estate1': RealEstate1SVG,
  'tooltip-trigger': TooltipTriggerSVG,
  remove: RemoveSavedSVG,
  address: AddressSVG,
  calendar: CalendarSVG,
  call: CallSVG,
  camera: CameraSVG,
  check: CheckSVG,
  checkmark: CheckmarkSVG,
  chevron: ChevronSVG,
  cross: CrossSVG,
  file: FileSVG,
  heart: HeartSVG,
  link: LinkSVG,
  list: ListSVG,
  mail: MailSVG,
  minus: MinusSVG,
  pen: PenSVG,
  pin: PinSVG,
  rocket: RocketSVG,
  search: SearchSVG,
  triangle: TriangleSVG,
  warning: WarningSVG,
  visa: VisaSVG,
  hand: HandSVG,
  happy: HappySVG,
  sad: SadSVG
};

export default icons;
