import { formatCurrency } from './rifmFormatters';
const { default: slugify } = require('slugify');

const DISCOUNT_COEFFICIENT = process.env.GATSBY_DISCOUNT_COEFFICIENT || 50;

// returns city, state, postal code
export const getAddress = ({ city, state, postalCode }) => {
  return `${[city, state].filter(Boolean).join(', ')} ${postalCode || ''}`;
};

export const getListingUrl = ({ id, address }) => {
  return `/listing/${id}/${slugify(address, { lower: true, strict: true })}`;
}

export const calculateDiscount = (price) => {
  if(!price) return '';
  const result = price * 0.025 * DISCOUNT_COEFFICIENT/100;
  return formatCurrency(Math.round(result/100)*100);
}