import React, { useContext } from 'react';
import { styled } from 'styles';
import SelectedPackage from './SelectedPackage';
import FlatRateContext from './context';

const SelectedPackageSection = () => {
  const { selectedPackage, total } = useContext(FlatRateContext);

  return (
    <StyledSelectedPackageSection className="package-section">
      <SelectedPackage total={total!} {...selectedPackage!} />
    </StyledSelectedPackageSection>
  );
};

const StyledSelectedPackageSection = styled.div`
  border-left: 1px solid ${props => props.theme.colors.creamyGray};
  padding-left: 38px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0;
    border-left: 0;
  }
`;

export default SelectedPackageSection;
