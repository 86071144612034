import React from 'react';
import { useForm } from 'react-hook-form';

import { register as registerRequest } from 'auth';
import Input from 'components/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { useAuthContext } from 'hooks';

interface SignUpFormProps {
  onSuccess: () => void;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ onSuccess }) => {
  const { login } = useAuthContext();
  const { register, watch, errors, setError, clearErrors, handleSubmit } = useForm();
  const errorText = Object.values(errors)[0]?.message;
  const password = watch('password');

  const submit = async values => {
    try {
      const authValues = { email: values.email, password: values.password };
      await registerRequest(authValues);
      await login(authValues);
      onSuccess();
    } catch (err) {
      const errorText = err.response?.errors?.email || err.response?.errors?.password || err.message;
      setError('general', { type: 'manual', message: errorText });
    }
  };

  const clearGeneralError = () => {
    if (errors.general) clearErrors('general');
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {errorText && (
        <div className="error">
          <Icon name="warning" />
          {errorText}
        </div>
      )}
      <Input
        name="email"
        ref={register({
          required: 'Required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        })}
        onChange={clearGeneralError}
        error={Boolean(errors.email)}
        placeholder="Email"
      />
      <Input
        name="password"
        ref={register({
          required: 'Required'
        })}
        onChange={clearGeneralError}
        error={Boolean(errors.password)}
        placeholder="Password"
        type="password"
      />
      <Input
        name="passwordConfirm"
        ref={register({
          required: 'Required',
          validate: value => value === password || `Passwords don't match`
        })}
        onChange={clearGeneralError}
        error={Boolean(errors.passwordConfirm)}
        placeholder="Confirm Password"
        type="password"
      />
      <Button primary type="submit" className="submit">
        Sign Up
      </Button>
    </form>
  );
};

export default SignUpForm;
