import { navigate } from 'gatsby';
import AuthModal from 'components/AuthModal';
import React, { useEffect, useMemo, useState } from 'react';

type ViewKey = 'sign_in' | 'sign_up' | 'forgot_password' | 'reset_password';

interface AuthModalContextValues {
  authModalOpen: boolean;
  setAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  forceOpen: boolean;
  setForceOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeView: ViewKey;
  setActiveView: React.Dispatch<React.SetStateAction<ViewKey>>;
  message?: string;
  setMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  resetPasswordToken: string | null | undefined;
  setResetPasswordToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AuthModalContext = React.createContext({} as AuthModalContextValues);

const getResetPasswordToken = () => {
  if (typeof window === 'undefined') return;
  const params = new URLSearchParams(window?.location.search);
  return params.get('forgot_password') || undefined;
};

export const AuthModalContextWrapper = ({ children }) => {
  const [resetPasswordToken, setResetPasswordToken] = useState<string | undefined>(
    getResetPasswordToken()
  );
  const defaultView = resetPasswordToken ? 'reset_password' : 'sign_in';
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);
  const [activeView, setActiveView] = useState<ViewKey>(defaultView);
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (authModalOpen) setActiveView(defaultView);
    else {
      setActiveView('sign_in');
      setMessage(undefined);
    }
  }, [authModalOpen, defaultView, resetPasswordToken]);

  useEffect(() => {
    if (resetPasswordToken) {
      setAuthModalOpen(true);
      const params = new URLSearchParams(window?.location.search);
      params.delete('forgot_password');
      const url = `${window.location.pathname}?${params.toString()}`;
      navigate(url, { replace: true });
    }
  }, [resetPasswordToken]);

  const values: AuthModalContextValues = {
    authModalOpen,
    setAuthModalOpen,
    forceOpen,
    setForceOpen,
    activeView,
    setActiveView,
    message,
    setMessage,
    resetPasswordToken,
    setResetPasswordToken
  };

  return (
    <AuthModalContext.Provider value={values}>
      <AuthModal />
      {children}
    </AuthModalContext.Provider>
  );
};

export default AuthModalContext;
