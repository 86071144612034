import React from 'react';
import LoaderComponent, { LoaderProps } from 'react-loader-spinner';
import { styled } from 'styles';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loader: React.FC<Partial<LoaderProps>> = ({ children, ...props }) => {
  const {
    type = 'Oval',
    color = '#FFFFFF',
    height = 100,
    width = 100,
    ...rest
  } = props || {};
  return (
    <FallbackContainer>
      <LoaderComponent
        type={type}
        color={color}
        height={height}
        width={width}
        {...rest}
      />
      {children}
    </FallbackContainer>
  );
};

export default Loader;

const FallbackContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
`;