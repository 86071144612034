import React from 'react';
import { styled } from 'styles';
import ContactForm from 'components/ContactForm';

const FormSection = () => {
  return (
    <StyledFormSection className="contact-form-section">
      <ContactForm source="Sell Page" />
    </StyledFormSection>
  );
};

const StyledFormSection = styled.div`
  padding: 72px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 32px);
    left: 16px;
    bottom: 0;
    background: ${props => props.theme.colors.lightGray};
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px 16px 25px;
  }
`;

export default FormSection;
