import React from 'react';

const ListingCardSkeleton: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="100%"
    height="276"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="listing-card-skeleton"
    {...props}>
    <rect width="100%" height="276" rx="3" fill="#FAFAFA" />
    <rect x="0" y="0" width="100%" height="155" rx="3" fill="#C4C4C4" />
    <rect x="12" y="171" width="116" height="16" rx="8" fill="#C4C4C4" />
    <rect x="12" y="199" width="45" height="12" rx="6" fill="#C4C4C4" />
    <rect x="12" y="223" width="137" height="12" rx="6" fill="#C4C4C4" />
    <rect x="12" y="247" width="121" height="12" rx="6" fill="#C4C4C4" />
    <rect x="65" y="199" width="45" height="12" rx="6" fill="#C4C4C4" />
    <rect x="118" y="199" width="45" height="12" rx="6" fill="#C4C4C4" />
  </svg>
);

export default ListingCardSkeleton;
