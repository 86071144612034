import React from 'react';
import { styled } from 'styles';
import clsx from 'clsx';
import { Checkbox } from 'components';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

interface UpgradeCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  price: number;
  selected?: boolean;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UpgradeCard: React.FC<UpgradeCardProps> = ({
  title,
  price,
  selected,
  onSelect,
  description
}) => {
  return (
    <StyledUpgradeCard className={clsx('upgrade-card')}>
      <Checkbox className="upgrade-card__checkbox" checked={selected} onChange={onSelect} />
      <div className="upgrade-card__container">
        <h4 className="upgrade-card__title">{title}</h4>
        <span className="upgrade-card__price">+ {currencyFormatter.format(price)}</span>
        <p className="upgrade-card__description">{description}</p>
      </div>
    </StyledUpgradeCard>
  );
};

const StyledUpgradeCard = styled.div`
  display: flex;
  border: 2px solid ${props => props.theme.colors.alto};
  border-radius: 6px;
  padding: 12px;
  align-items: flex-start;
  position: relative;
  margin: 0 0 12px;

  .upgrade-card {
    &__container {
      margin: 0 0 0 8px;
    }
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 5px;
      padding-right: 80px;
    }
    &__description {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
    }
    &__price {
      position: absolute;
      top: 12px;
      right: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    &__checkbox {
      margin: 3px 0 0;
      input {
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }
`;
export default UpgradeCard;
