import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { styled } from 'styles';
import { Button } from 'components';
import FlatRateContext from './context';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

interface PackageBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  price: number;
  duration?: string;
  total?: number;
  title: string;
}

const PackageBlock: React.FC<PackageBlockProps> = ({
  price,
  duration,
  total,
  title,
  className
}) => {
  const { t } = useTranslation();
  const { selectedAddons, setSelectedAddonsIds } = useContext(FlatRateContext);
  const handleChangeClick = () => {
    const node = document.querySelector('#package-section');
    node?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <StyledPackageBlock className={clsx('package-block', className)}>
      <div className="package-block__container">
        <div className="package-block__header">
          <h4 className="package-block__title">{t('sell_page.selected_package')}</h4>
          <Button simple className="package-block__btn-control" onClick={handleChangeClick}>
            {t('common.change')}
          </Button>
        </div>
        <div className="selected-package">
          <h6 className="selected-package__title">{title}</h6>
          <div className="selected-package__info">
            <span className="selected-package__price">{currencyFormatter.format(price)}</span>
            {duration !== undefined && (
              <span className="selected-package__duration">/ {duration}</span>
            )}
          </div>
        </div>
      </div>
      {selectedAddons.length !== 0 && (
        <div className="package-block__container">
          <div className="package-block__header">
            <h4 className="package-block__title">{t('sell_page.addons')}</h4>
          </div>
          <ul className="addons-list">
            {selectedAddons.map(({ id, title, price }) => {
              return (
                <li className={clsx('addons-list__item')} key={id}>
                  <span className="title">{title}</span>
                  <div className="addons-list__item-box">
                    <span className="price">{currencyFormatter.format(price)}</span>
                    <Button
                      simple
                      className="btn-remove"
                      onClick={() =>
                        setSelectedAddonsIds(items => items.filter(item => item !== id))
                      }>
                      {t('common.remove')}
                    </Button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {total && (
        <div className="package-block__container total">
          <div className="package-block__header">
            <h4 className="package-block__title">{t('sell_page.total')}</h4>
            <span className="selected-package__price">{currencyFormatter.format(total)}</span>
          </div>
        </div>
      )}
    </StyledPackageBlock>
  );
};

const StyledPackageBlock = styled.div`
  position: sticky;
  top: 40px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    position: unset;
  }

  .package-block {
    &__container {
      &.total {
        border-top: 1px solid ${props => props.theme.colors.creamyGray};
        padding: 12px 0;
        .package-block__title {
          color: #000;
          font-size: 16px;
        }
      }
    }
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      color: ${props => props.theme.colors.etherealGrey};
    }
    &__btn-control {
      color: ${props => props.theme.colors.primary};
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 16px;
    }
  }
  .selected-package {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    margin: 0 0 26px;

    &__info {
      display: flex;
      align-items: center;
    }
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      margin: 0;
    }
    &__price {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    &__duration {
      font-weight: 300;
      font-size: 12px;
      line-height: 22px;
      color: #9c9c9c;
      margin: 0 0 0 5px;
      text-transform: lowercase;
    }
  }

  .addons-list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item-box {
      display: flex;
      align-items: center;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 16px;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
      }
      .price {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin: 0 33px 0 0;
      }
      .btn-remove {
        color: ${props => props.theme.colors.primary};
        font-size: 12px;
        line-height: 22px;
        font-weight: 300;
      }
    }
  }
`;
export default PackageBlock;
