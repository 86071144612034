/* eslint-disable no-new */
import React, { useEffect, useContext } from 'react';
import GoogleMapReact, {
  Props as GoogleMapReactProps,
  Coords,
  ChangeEventValue
} from 'google-map-react';

import { styled } from 'styles';
import { ListingCardMapList } from 'components/ListingCard';
import Modal from '../Modal';
import MapContext, { MapContextProvider } from './mapContext';
import ListingPin from './ListingPin';
import Cluster from './Cluster';

const apiKey = process.env.GATSBY_GOOGLE_KEY || '';
const libraries = ['drawing'];
const defaultCenter = { lat: 41.8339032, lng: -87.8723893 };

const ClusterPosition = ({ children }: { lat: any; lng: any; children: any }) => children;

interface MapProps extends GoogleMapReactProps {
  listings?: ListingsSearchMarker[];
  center?: Coords;
  onLoad?: (maps: { map: any; maps: any; ref: Element | null }) => void;
  onChange?: (values: ChangeEventValue) => void;
}

const Map: React.FC<MapProps> = ({
  children,
  listings = [],
  center = defaultCenter,
  onLoad,
  onChange,
  ...props
}) => {
  const {
    clusterListingsModalItems,
    clusters,
    setBounds,
    setClusterListingsModalItems,
    setListings,
    setMapInstance,
    setZoom,
    zoomDisabled
  } = useContext(MapContext);

  const handleApiLoaded = maps => {
    setMapInstance(maps.map);
    if (onLoad) onLoad(maps);
  };

  useEffect(() => {
    setListings(listings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(listings)]);

  return (
    <StyledMapWrapper className="map">
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        options={{ disableDoubleClickZoom: zoomDisabled, scrollwheel: !zoomDisabled }}
        bootstrapURLKeys={{ key: apiKey, libraries }}
        center={center}
        defaultCenter={defaultCenter}
        defaultZoom={11}
        onGoogleApiLoaded={handleApiLoaded}
        onChange={values => {
          const { zoom, bounds } = values;
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
          if (onChange) onChange(values);
        }}
        {...props}>
        {clusters.map((cluster, idx) => {
          const [longitude, latitude] = cluster.geometry.coordinates;

          if (cluster.properties.cluster) {
            return (
              <ClusterPosition key={cluster.id} lat={latitude} lng={longitude}>
                <Cluster data={cluster} data-cy={`map_cluster_${cluster.id}`} />
              </ClusterPosition>
            );
          }

          return (
            <ListingPin
              // some listings are duplicates
              // eslint-disable-next-line react/no-array-index-key
              key={`${cluster.properties.data.id}-${idx}`}
              lat={latitude}
              lng={longitude}
              data={cluster.properties.data}
            />
          );
        })}
        {children}
      </GoogleMapReact>
      <StyledClusterItemsModal
        open={Boolean(clusterListingsModalItems)}
        modalTitle="Listings"
        onClose={() => setClusterListingsModalItems(undefined)}>
        {clusterListingsModalItems && <ListingCardMapList ids={clusterListingsModalItems} />}
      </StyledClusterItemsModal>
    </StyledMapWrapper>
  );
};

const MapWrapper: React.FC<MapProps> = props => (
  <MapContextProvider>
    <Map {...props} />
  </MapContextProvider>
);

export default MapWrapper;

const StyledMapWrapper = styled.div`
  height: 300px;

  .cluster-marker {
    color: #fff;
    background: ${props => props.theme.colors.primary};
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const StyledClusterItemsModal = styled(Modal)`
  .modal-content {
    padding: 10px;
  }

  .modal-body {
    width: 320px;
    margin: 10px -10px -10px -10px;
    padding: 0 10px 10px 10px;

    .property-card-wrapper {
      display: block;
      margin: 10px 0;
      width: 100%;

      .property-card {
        width: 100%;
      }
    }

    .listing-card-skeleton {
      margin: 10px 0;
    }

    max-height: 600px;
    overflow-y: auto;
  }
`;
