import React from 'react';
import { useForm } from 'react-hook-form';

import { resetPassword } from 'auth';
import Input from 'components/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { tryGetFirstError } from 'utils/requests';

interface ResetPasswordFormProps {
  token: string;
  onSuccess: () => void;
  onReturnClick: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  token,
  onSuccess,
  onReturnClick
}) => {
  const { register, watch, errors, setError, clearErrors, handleSubmit } = useForm();
  const errorText = Object.values(errors)[0]?.message;
  const password = watch('password');

  const submit = async values => {
    try {
      await resetPassword({
        ...values,
        key: token
      });
      onSuccess();
    } catch (err) {
      setError('general', {
        type: 'manual',
        message: tryGetFirstError(err.response) || err.message
      });
    }
  };

  const clearGeneralError = () => {
    if (errors.general) clearErrors('general');
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {errorText && (
        <div className="error">
          <Icon name="warning" />
          {errorText}
        </div>
      )}
      <Input
        name="password"
        ref={register({
          required: 'Required'
        })}
        onChange={clearGeneralError}
        error={Boolean(errors.password)}
        placeholder="Password"
        type="password"
      />
      <Input
        name="passwordConfirm"
        ref={register({
          required: 'Required',
          validate: value => value === password || `Passwords don't match`
        })}
        onChange={clearGeneralError}
        error={Boolean(errors.passwordConfirm)}
        placeholder="Confirm Password"
        type="password"
      />
      <Button primary type="submit" className="submit">
        Submit
      </Button>
      <Button simple primary type="button" className="forgot-pass" onClick={onReturnClick}>
        Back to Sign In
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
