import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';

import notify from 'notify';
import { styled } from 'styles';
// import { REGEXP } from 'consts';
import getFirstFormError from 'utils/getFirstFormError';
import { /* Input, */ Button } from 'components';
import StripeInput from 'components/StripeInput';
import { sendSellprocessData } from './api';
import FlatRateContext from './context';

const stripeAccount = process.env.GATSBY_STRIPE_ACCOUNT;

const PaymentBlock = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const stripeLoaded = Boolean(stripe && elements);
  const {
    origin,
    disablePayment,
    propertyInfo,
    selectedAddonsIds,
    packageId,
    total,
    setSubmitResult,
    setSellprocessID,
    setDisablePayment
  } = useContext(FlatRateContext);
  const { /* register, */ errors, setError, clearErrors, handleSubmit, formState } = useForm();
  const error = getFirstFormError(errors);

  useEffect(() => {
    if (formState.submitCount !== 0) setDisablePayment(!formState.isValid);
  }, [formState.submitCount, formState.isValid, setDisablePayment]);

  const submit = async _values => {
    try {
      setDisablePayment(true);
      if (!stripe || !elements) throw Error('Stripe is not loaded');
      const cardNumber = elements.getElement(CardNumberElement);
      if (!cardNumber) throw Error('No card number element');

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {
          // name: values.name,
          email: propertyInfo?.email
        }
      });
      if (error) {
        // eslint-disable-next-line no-console
        console.error('[stripe]', error);
        throw Error(error.message);
      }

      const sellprocessID = await sendSellprocessData({
        origin,
        paymentMethod,
        propertyInfo,
        total,
        selectedAddons: selectedAddonsIds,
        packageId,
        stripeAccount
      });
      setSellprocessID(sellprocessID);
      notify('Success');
      setSubmitResult('success');
    } catch (err) {
      notify(err.message);
      setSubmitResult('failure');
    } finally {
      setDisablePayment(false);
    }
  };

  const handleStripeInputChange = e => {
    if (e.error) {
      setError(e.elementType, { type: 'srtipe', message: e.error.message });
    } else {
      clearErrors(e.elementType);
    }
  };

  return (
    <StyledPaymentBlock className="payment-block" id="payment-block">
      <h3 className="payment-block__title">{t('sell_page.step4')}</h3>
      <form className="payment-form" onSubmit={handleSubmit(submit)}>
        <div className="payment-form__container">
          <StripeInput
            type="number"
            label={t('sell_page.card_number')}
            className="payment-form__item"
            placeholder="XXXX-XXXX-XXXX-XXXX"
            onChange={handleStripeInputChange}
          />
          {/* <Input
            ref={register({
              required: 'Required',
              maxLength: {
                value: 55,
                message: `Name can not exceed 55 characters`
              },
              pattern: {
                value: REGEXP.NAME,
                message: 'Invalid name'
              }
            })}
            name="name"
            label={t('sell_page.cardholder_name')}
            placeholder={t('sell_page.placeholder_cardholder_name')}
            error={errors.name}
            className="payment-form__item"
          /> */}
        </div>
        <div className="payment-form__container">
          <div className="payment-form__container">
            <StripeInput
              type="expiry"
              label={t('sell_page.expiration_date')}
              className="payment-form__item"
              placeholder="MM/YY"
              onChange={handleStripeInputChange}
            />
            <StripeInput
              type="cvc"
              label="CVV"
              className="payment-form__item"
              placeholder="CVV"
              onChange={handleStripeInputChange}
            />
          </div>
        </div>
        <p className="payment-form__error">{error}</p>
        <Button
          primary
          className="payment-block__pay-btn"
          disabled={!stripeLoaded || disablePayment}>
          {t('sell_page.pay')}
        </Button>
      </form>
    </StyledPaymentBlock>
  );
};

const StyledPaymentBlock = styled.div`
  padding: 0 0 72px;
  .payment-block {
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 24px;
    }
    &__pay-btn {
      font-size: 12px;
      line-height: 14px;
      min-width: 118px;
    }
  }
  .payment-form {
    &__item {
      width: 100%;
      margin: 0 0 20px;
      .input {
        &__component {
          height: 40px;
        }
        &__label {
          font-weight: normal;
        }
      }
      &.has-icon {
        .input {
          &__component {
            padding-right: 38px;
          }
        }
      }
    }
    &__container {
      display: grid;
      grid-gap: 0 24px;
      grid-template-columns: repeat(2, 1fr);
      .property-form__item {
        margin: 0 0 24px;
      }
    }
    &__error {
      font-size: 12px;
      color: ${props => props.theme.colors.errorRed};
      &:empty {
        display: none;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .payment-block {
      &__title {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 16px;
      }
      &__pay-btn {
        width: 100%;
      }
    }
    .payment-form {
      &__container {
        grid-gap: 0;
        grid-template-columns: 1fr;
        .payment-form__item {
          margin: 0 0 20px;
        }
      }
    }
  }
`;

export default PaymentBlock;
