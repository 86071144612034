import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import Supercluster from 'supercluster';
import useSupercluster from 'use-supercluster';
import { useQueryParam, BooleanParam, withDefault } from 'use-query-params';

import { CluserPoint } from '.';

type Bounds = [number, number, number, number];

interface MapContextValues {
  bounds?: Bounds;
  clusterListingsModalItems: string[] | undefined;
  clusterPoints: CluserPoint[];
  clusters: CluserPoint[];
  fitBounds: () => void;
  listings: ListingsSearchMarker[];
  map?: google.maps.Map;
  maxClusterZoom: number;
  setBounds: React.Dispatch<React.SetStateAction<Bounds | undefined>>;
  setClusterListingsModalItems: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  setListings: (listings: ListingsSearchMarker[]) => void;
  setMapInstance: (map: google.maps.Map) => void;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  setZoomDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  supercluster?: Supercluster<Supercluster.AnyProps, Supercluster.AnyProps>;
  zoom: number;
  zoomDisabled: boolean;
  openPin: string | null;
  setOpenPin: React.Dispatch<React.SetStateAction<string | null>>;
}

const MapContext = React.createContext({} as MapContextValues);

const maxClusterZoom = 22;

export const MapContextProvider = ({ children }) => {
  const mapRef = useRef<google.maps.Map>();
  const map: google.maps.Map | undefined = mapRef ? mapRef.current : undefined;
  const [listings, _setListings] = useState<ListingsSearchMarker[]>([]);
  const [bounds, setBounds] = useState<Bounds>();
  const [zoom, setZoom] = useState(10);
  const [clusterListingsModalItems, setClusterListingsModalItems] = useState<string[]>();
  const [zoomDisabled_, setZoomDisabled] = useState(false);
  const [openPin, setOpenPin] = useState<string | null>(null);
  const zoomDisabled = zoomDisabled_ || Boolean(clusterListingsModalItems) || Boolean(openPin);
  const [mapBoundsFitted, setMapBoundsFitted] = useQueryParam(
    'mapBoundsFitted',
    withDefault(BooleanParam, false)
  );

  const clusterPoints = useMemo(
    () =>
      listings.map(listing => ({
        type: 'Feature',
        properties: { cluster: false, data: listing, category: 'listing' },
        geometry: {
          type: 'Point',
          coordinates: [listing.longitude, listing.latitude]
        }
      })),
    [listings]
  ) as any;

  const { clusters, supercluster } = useSupercluster({
    points: clusterPoints,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: maxClusterZoom }
  });

  const setMapInstance = map => {
    mapRef.current = map;
  };

  const setListings = (listings: ListingsSearchMarker[]) => {
    _setListings(listings.filter(listing => listing.latitude && listing.longitude));
  };

  const fitBounds = useCallback(() => {
    if (!map || !listings.length) return;
    const bounds = new google.maps.LatLngBounds();
    listings.forEach(item => bounds.extend({ lat: item.latitude, lng: item.longitude }));
    map.fitBounds(bounds);
    setMapBoundsFitted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listings, map]);

  useEffect(() => {
    if (!mapBoundsFitted) fitBounds();
  }, [fitBounds, mapBoundsFitted]);

  const values: MapContextValues = {
    bounds,
    clusterListingsModalItems,
    clusterPoints,
    clusters: clusters as CluserPoint[],
    fitBounds,
    listings,
    map,
    maxClusterZoom,
    setBounds,
    setClusterListingsModalItems,
    setListings,
    setMapInstance,
    setZoom,
    setZoomDisabled,
    supercluster,
    zoom,
    zoomDisabled,
    openPin,
    setOpenPin
  };
  return <MapContext.Provider value={values}>{children}</MapContext.Provider>;
};

export default MapContext;
