import React from 'react';
import { useForm } from 'react-hook-form';

import { forgotPassword } from 'auth';
import Input from 'components/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';

interface ForgotPasswordFormProps {
  onSuccess: () => void;
  onReturnClick: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSuccess, onReturnClick }) => {
  const { register, errors, setError, clearErrors, handleSubmit } = useForm();
  const errorText = Object.values(errors)[0]?.message;

  const submit = async values => {
    try {
      await forgotPassword(values);
      onSuccess();
    } catch (err) {
      setError('general', { type: 'manual', message: err.response?.errors || err.response || err.message });
    }
  };

  const clearGeneralError = () => {
    if (errors.general) clearErrors('general');
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {errorText && (
        <div className="error">
          <Icon name="warning" />
          {errorText}
        </div>
      )}
      <Input
        name="email"
        ref={register({
          required: 'Required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        })}
        onChange={clearGeneralError}
        error={errors.email}
        placeholder="Email"
      />
      <Button primary type="submit" className="submit">
        Submit
      </Button>
      <Button simple primary type="button" className="forgot-pass" onClick={onReturnClick}>
        Back to Sign In
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
