import React from 'react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import photo from 'assets/images/family3.jpg';
import { Button } from 'components';

const FeeSection = () => {
  const { t } = useTranslation();

  return (
    <StyledFeeSection className="fee-section">
      <div className="fee-section-wrapper">
        <div className="fee-info">
          <div className="fee-info-wrapper">
            <h4 className="fee-info__title">{t('home_page.fee_title')}</h4>
            <p className="fee-info__description">{t('home_page.fee_description')}</p>
            <Button className="fee-info__button" primary onClick={() => navigate('/sell')}>
              {t('common.sell_with_us')}
            </Button>
          </div>
        </div>
        <div className="fee-photo">
          <img src={photo} alt="fee-photo" />
        </div>
      </div>
    </StyledFeeSection>
  );
};

const StyledFeeSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;

  .fee-section-wrapper {
    display: flex;
    position: relative;
    max-width: 1334px;
    padding: 48px 0;
    flex: 1;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.lightGray};
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .fee-photo {
    display: flex;
    flex: 1;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .fee-info {
    display: flex;
    flex: 1;
    padding: 20px 16px 16px 40px;
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 16px;
      color: ${props => props.theme.colors.anthracite};
    }
    &__description {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: ${props => props.theme.colors.lightBlack};
      margin: 0 0 34px;
    }
    &__button {
      width: 140px;
    }
  }
  .fee-info-wrapper {
    max-width: 342px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    position: relative;
    padding: 0 16px;
    .fee-section-wrapper {
      position: static;
      grid-template-columns: minmax(374px, 831px) max-content;
      &:before {
        width: calc(100% - 32px);
        left: 16px;
      }
    }
    .fee-info-wrapper {
      max-width: 304px;
    }
    .fee-info {
      &__title {
        max-width: 223px;
        line-height: 28px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    position: relative;
    padding: 0 16px;
    .fee-section-wrapper {
      display: flex;
      flex-direction: column-reverse;
      padding: 32px 0;
    }
    .fee-info-wrapper {
      padding: 0;
      max-width: 100%;
      width: 100%;
    }
    .fee-info {
      padding: 32px 0 0;
      &__title {
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
        margin: 0 28px 20px 0;
      }
      &__button {
        width: 100%;
        height: 40px;
        font-size: 12px;
        line-height: 24px;
      }
      &__description {
        margin: 0 0 20px;
      }
    }
  }
`;

export default FeeSection;
