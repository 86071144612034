import React from 'react';

import { styled } from 'styles';
import { ReactComponent as MapSkeletonIconSVG } from './mapSkeletonIcon.svg';

const MapSkeleton: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <StyledMapSkeleton {...props}>
    <MapSkeletonIconSVG />
  </StyledMapSkeleton>
);

export default MapSkeleton;

const StyledMapSkeleton = styled.div`
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
`;
