import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from 'styles';
import { Button } from 'components';
import UpgradeCard from './UpgradeCard';
import FlatRateContext from './context';
// import addons from './addons.json';

const UpgradeInfo = () => {
  const { t } = useTranslation();
  const {
    addons,
    selectedAddonsIds,
    setSelectedAddonsIds,
    showPayment,
    setShowPayment
  } = useContext(FlatRateContext);

  const handleProceedClick = () => {
    setShowPayment(true);

    setTimeout(() => {
      const paymentBlock = document.querySelector('#payment-block');
      paymentBlock?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
  };

  return (
    <StyledUpgradeInfo className="upgrade-info" id="addons-section">
      <h3 className="upgrade-info__title">3. Select Add-ons / Upgrade</h3>
      <div className="upgrade-cards">
        {addons.map(({ id, ...itemProps }) => (
          <UpgradeCard
            {...itemProps}
            key={id}
            selected={selectedAddonsIds.includes(id)}
            onSelect={e => {
              e.persist();
              setSelectedAddonsIds(items =>
                e.target.checked ? [...items, id] : items.filter(item => item !== id)
              );
            }}
          />
        ))}
      </div>
      {!showPayment && (
        <Button primary className="upgrade-info__go-pay-btn" onClick={handleProceedClick}>
          {t(
            selectedAddonsIds.length === 0
              ? 'sell_page.proceed_to_payment_skip'
              : 'sell_page.proceed_to_payment'
          )}
        </Button>
      )}
    </StyledUpgradeInfo>
  );
};

const StyledUpgradeInfo = styled.div`
  padding: 0 0 72px;
  .upgrade-info {
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 24px;
    }
    &__go-pay-btn {
      width: 216px;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .upgrade-cards {
    padding: 0 0 12px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .upgrade-info {
      &__title {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 16px;
      }
      &__go-pay-btn {
        width: 100%;
      }
    }
  }
`;

export default UpgradeInfo;
