import React from 'react';
import { ReactComponent as MarkerSVG } from 'assets/icons/marker.svg';
import { styled } from 'styles';

interface MarkerProps {
  lat: number;
  lng: number;
}

const Marker: React.FC<MarkerProps> = () => {
  return (
    <StyledMarker>
      <MarkerSVG />
    </StyledMarker>
  );
};

export default Marker;

const StyledMarker = styled.div`
  svg {
    fill: ${props => props.theme.colors.primary};
    transform: translate(-50%, -100%);

    .marker_svg__accent {
      fill: ${props => props.theme.colors.primaryAccent};
    }
  }
`;
