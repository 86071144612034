import { info } from 'sitedata';

const defaultSearch = info.defaultSearch || 'Chicago';
const { searchUrlListingStatuses = '' } = info;

export default {
  sell: '/sell',
  buy: `/search/?${searchUrlListingStatuses}search_string=${defaultSearch}`,
  savedListings: '/saved-listings',
  savedSearches: '/saved-searches',
  ourTeam: '/our-team',
  aboutUs: '/about-us'
};
