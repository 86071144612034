import React, { useRef, useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { styled } from 'styles';
import useOutsideClick from 'hooks/useOutsideClick';
import { Button, Icon } from 'components';
import clsx from 'clsx';
import { Placement } from '@popperjs/core';

interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  trigger?: React.ReactNode;
  placement?: Placement;
}

const Tooltip: React.FC<TooltipProps> = ({
  className,
  trigger,
  placement = 'bottom',
  children,
  ...props
}) => {
  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const arrowElement = useRef(null);
  const [open, setOpen] = useState(false);
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      modifiers: [{ name: 'arrow', options: { element: arrowElement.current } }]
    }
  );

  useOutsideClick(popperElement, e => {
    if ((referenceElement.current as HTMLDivElement | null)?.contains(e.target)) return;
    if (open) setOpen(false);
  });

  useEffect(() => {
    forceUpdate?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, forceUpdate]);

  return (
    <>
      <StyledTriggerContainer
        ref={referenceElement}
        className="trigger"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}>
        {trigger}
      </StyledTriggerContainer>
      <StyledTooltip
        ref={popperElement}
        style={styles.popper}
        {...props}
        {...attributes.popper}
        className={clsx('tooltip', { open }, className)}>
        <div className="tooltip__arrow" ref={arrowElement} style={styles.arrow} />
        {open && (
          <div className="content">
            <div className="content-header">
              <Button onClick={() => setOpen(false)} className="button-close" simple type="button">
                <Icon name="mini-x" size={8} />
              </Button>
            </div>
            {children}
          </div>
        )}
      </StyledTooltip>
    </>
  );
};

const StyledTriggerContainer = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

const StyledTooltip = styled.div`
  z-index: 100;

  &:not(.open) {
    display: none;
  }

  .tooltip__arrow {
    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: white;
    }
  }

  &[data-popper-placement='bottom'] {
    margin-top: 10px;
    .tooltip__arrow {
      margin-top: -5px;
    }
  }
  &[data-popper-placement='left'] {
    margin: -2px 10px 0 0;
    .tooltip__arrow {
      right: 0;
      margin-right: -5px;
    }
  }
  &[data-popper-placement='right'] {
    margin-left: 10px;
    .tooltip__arrow {
      left: 0;
      margin-left: -5px;
    }
  }

  &[data-popper-placement='bottom-end'] {
    margin: 10px 0 0 10px;
    .tooltip__arrow {
      margin: -5px 0 0 -10px;
    }
  }

  .content {
    background: white;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 12px;
    width: 320px;
  }
  .content-header {
    width: 100%;
    display: flex;
    min-height: 8px;
    z-index: 110;
    .button-close {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 12px;
      right: 12px;
      fill: ${props => props.theme.colors.primary};
      border: 0;
      margin: 0 0 0 auto;

      svg {
        flex-shrink: 0;
        margin: 0;
      }
    }
  }
`;

export default Tooltip;
