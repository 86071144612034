/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form/dist/types/form';

import useOutsideClick from 'hooks/useOutsideClick';
import { styled } from 'styles';
import { Icon, IconName } from 'components';

export type DropdownOption = { value: any; text?: React.ReactNode };
export interface DropdownProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: React.ReactNode;
  onChange?: (value: string) => void;
  options: DropdownOption[];
  label?: React.ReactNode;
  placeholder?: string;
  error?: FieldError | boolean;
  simple?: boolean;
  noPopper?: boolean;
  placement?: Placement;
  icon?: IconName;
  render?: (data: Record<string, any>) => JSX.Element;
}

const Dropdown: React.FC<DropdownProps> = ({
  value,
  onChange,
  options = [],
  className,
  children,
  placeholder,
  error,
  simple,
  noPopper,
  placement = 'bottom-start',
  icon = 'triangle',
  label,
  render,
  ...props
}) => {
  const dropdownRef = useRef<HTMLButtonElement | null>(null);
  const popperElement = useRef<HTMLDivElement | null>(null);
  const { styles, attributes, forceUpdate } = usePopper(
    dropdownRef.current,
    popperElement.current,
    {
      placement,
      modifiers: [
        { name: 'flip', options: { fallbackPlacements: ['top-start'] } },
        { name: 'offset', options: { offset: [0, 4] } }
      ]
    }
  );
  const [open, setOpen] = useState(false);

  useOutsideClick(popperElement, () => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    if (open && forceUpdate) forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOptionClick = option => {
    setOpen(false);
    onChange?.(option.value);
  };

  const content = render
    ? render({
        close: () => setOpen(false)
      })
    : children;

  const menuProps = noPopper
    ? undefined
    : { ref: popperElement, style: styles.popper, ...attributes.popper };

  return (
    <StyledDropdown
      className={clsx(
        'dropdown',
        { simple, open, placeholder: !value, error: Boolean(error) },
        className
      )}
      {...props}>
      <label className="dropdown__label">{label}</label>
      <button
        ref={dropdownRef}
        onMouseDown={() => setOpen(v => !v)}
        type="button"
        className="dropdown__button"
        data-cy={`filter_dropdown_${placeholder ||
          (typeof value === 'string' ? value.replace(/ /g, '_') : 'sorting')}`}>
        <div className="dropdown__text">{value || placeholder}</div>
        <Icon className="dropdown__icon" name={icon} size={6} />
      </button>
      <div className={clsx('dropdown__menu', { open })} {...menuProps}>
        {options.map(option => (
          <StyledDropdownOption
            className={clsx('dropdown__option', { active: value === option.value }, className)}
            key={option.value}
            onClick={() => handleOptionClick(option)}
            type="button">
            {value === option.value && (
              <Icon name="check" className="dropdown__option__active-icon" />
            )}
            {option.text || option.value}
          </StyledDropdownOption>
        ))}
        {content}
      </div>
      {typeof error === 'object' && error?.message && (
        <div className="dropdown__error">{error.message}</div>
      )}
    </StyledDropdown>
  );
};

export default Dropdown;

const StyledDropdown = styled.div`
  display: inline-block;
  position: relative;
  width: 123px;
  border: 1px solid transparent;

  &.open {
    .dropdown__icon {
      transform: rotate(180deg);
    }
    .dropdown__button {
      border-color: ${props => props.theme.colors.pastelGray};
    }
  }

  &.placeholder .dropdown__button {
    color: #8e8e8e;
  }

  &.simple {
    width: auto;

    .dropdown__button {
      height: auto;
      border: none;
      font-size: unset;
      line-height: unset;
      border: 0;
      &:hover {
        border: 0;
      }
      .icon-triangle {
        margin-left: 4px;
        fill: unset;
      }
    }
  }

  &.error {
    .dropdown__button {
      border: 1px solid ${props => props.theme.colors.red};
    }
  }

  .dropdown__label {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;

    &:empty {
      display: none;
    }
  }

  .dropdown__button {
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    height: 36px;
    padding: 0 8px;
    border: 1px solid ${props => props.theme.colors.borderColor};
    border-radius: ${props => props.theme.misc.borderRadius};
    transition: ${props => props.theme.transitions.standart};
    font-size: 12px;
    line-height: 16px;
    outline: none;
    background: none;
    cursor: pointer;
    &:hover {
      border: 1px solid ${props => props.theme.colors.pastelGray};
    }
  }

  .dropdown__error {
    margin: 4px 0 0;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.red};
  }

  .icon-triangle {
    flex-shrink: 0;
    margin-left: auto;
    fill: ${props => props.theme.colors.borderColor};
    transition: ${props => props.theme.transitions.standart};
  }

  .icon-chevron {
    flex-shrink: 0;
  }

  .dropdown__text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    min-width: 85px;
    text-align: left;
  }

  .dropdown__menu {
    background: #fff;
    min-width: 100%;
    max-height: 234px;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.25);
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 12px 0;
    font-size: 12px;
    line-height: 16px;
    z-index: 1000;
    overflow: auto;

    &:not(.open) {
      display: none;
    }
  }
`;

const StyledDropdownOption = styled.button`
  display: block;
  width: calc(100% + 24px);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 12px;
  background: none;
  border: none;
  outline: none;
  text-align: unset;
  color: black;
  text-decoration: none;
  cursor: pointer;

  .dropdown__option__active-icon {
    vertical-align: middle;
    margin: 0 8px 2px 0;
    fill: ${props => props.theme.colors.green};
  }

  &:hover {
    background: #f8f8f8;
  }
`;
