import React from 'react';

import { styled } from 'styles';
import { Link } from 'gatsby';
import notify from 'notify';
import { useAuthContext } from 'hooks';
import Dropdown, { DropdownOption, DropdownProps } from './DropdownMenu';

// remove types
const DropdownOptionLink = DropdownOption as any;

interface AuthMenuProps extends DropdownProps {
  onClose?: () => void;
}

const AuthMenu: React.FC<AuthMenuProps> = ({ onClose, ...props }) => {
  const { logout, logoutManually, profile } = useAuthContext();

  const handleLogoutClick = async () => {
    if (onClose) onClose();
    try {
      await logout();
      notify('Logged out');
    } catch (err) {
      logoutManually();
      notify(err.message);
    }
  };

  return (
    <StyledDropdown
      simple
      icon="chevron"
      placement="bottom-end"
      value={profile?.email || 'no email'}
      {...props}>
      {/* <DropdownOptionLink as={Link} to="/dashboard">
        Go to dashboard
      </DropdownOptionLink>
      <DropdownOptionLink as={Link} to="/profile">
        See profile
      </DropdownOptionLink> */}
      <DropdownOptionLink as={Link} to="/profile">
        My profile
      </DropdownOptionLink>
      <DropdownOption onClick={handleLogoutClick}>Log out</DropdownOption>
    </StyledDropdown>
  );
};

export default AuthMenu;

const StyledDropdown = styled(Dropdown)`
  .dropdown__button {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    color: inherit;
    fill: inherit;

    .icon.icon-chevron {
      width: 16px;
      height: 16px;
      margin-right: -2px;
      margin-left: 5px;
    }
  }

  .dropdown__menu {
    padding: 4px 0;
    width: 120px;
    min-width: unset;

    .dropdown__option {
      margin: 0;
      width: 100%;
    }
  }
`;
