/* eslint-disable no-console */
import React, { useState } from 'react';
import withSocialLogin, { Provider } from 'react-social-login';
import { Button, ButtonProps } from 'components';
import notify from 'notify';

const googleAppId = process.env.GATSBY_GOOGLE_APP_ID;
const facebookAppId = process.env.GATSBY_FACEBOOK_APP_ID;

const SocialComponent = withSocialLogin(({ children, triggerLogin, ...props }) => {
  return (
    <Button {...props} onClick={triggerLogin}>
      {children}
    </Button>
  );
});

interface SocialButtonProps extends ButtonProps {
  provider: Provider;
  onSuccess?: (result: { provider: string; token: string }) => void;
}

const SocialButton: React.FC<SocialButtonProps> = ({ children, provider, onSuccess, ...props }) => {
  const [error, setError] = useState(false);

  let token;
  switch (provider) {
    case 'google':
      if (!googleAppId) {
        console.error('Google client id is missing');
        return null;
      }
      token = googleAppId;
      break;
    case 'facebook':
      if (!facebookAppId) {
        console.error('Facebook client id is missing');
        return null;
      }
      token = facebookAppId;
      break;
    default:
      throw Error('No token or invalid provider prop');
  }

  const handleLoginFailure = (msg: string) => {
    // https://github.com/deepakaggarwal7/react-social-login/issues/107
    setError(true);
    setError(false);
    if (!msg.includes('popup_closed_by_user')) notify(`Social auth error: ${msg}`);
  };

  const handleLoginSuccess = async result => {
    if (!onSuccess) return;
    onSuccess({
      provider: result.provider,
      token: provider === 'google' ? result.token.idToken : result.token.accessToken
    });
  };

  return !error ? (
    <SocialComponent
      scope="email"
      appId={token}
      provider={provider}
      onLoginFailure={handleLoginFailure}
      onLoginSuccess={handleLoginSuccess}
      {...props}>
      {children}
    </SocialComponent>
  ) : null;
};

export default SocialButton;
