/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import { Icon } from 'components';

interface NumberSelectorProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  label?: React.ReactNode;
  plusMore?: boolean;
  value?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
}

const NumberSelector: React.FC<NumberSelectorProps> = ({
  className,
  value = 0,
  plusMore,
  min,
  max,
  label,
  onChange,
  ...props
}) => {
  const handleButtonClick = e => {
    const newValue = e.target.value === 'decrease' ? Number(value) - 1 : Number(value) + 1;
    if (onChange) onChange(newValue);
  };

  return (
    <StyledNumberSelector className={clsx('number-selector', className)} {...props}>
      <label>{label}</label>
      <div className="number-selector__buttons">
        <button
          type="button"
          value="decrease"
          onClick={handleButtonClick}
          disabled={min !== undefined && value <= min}>
          <Icon name="minus" />
        </button>
        <span className="number-selector__value">
          {Number(value)}
          {plusMore && '+'}
        </span>
        <button
          type="button"
          value="increase"
          onClick={handleButtonClick}
          disabled={max !== undefined && value >= max}>
          <Icon name="plus-mini" />
        </button>
      </div>
    </StyledNumberSelector>
  );
};

export default React.memo(NumberSelector);

const StyledNumberSelector = styled.div`
  display: inline-block;
  width: 134px;

  label {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .number-selector__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 36px;
      height: 36px;
      border: 1px solid ${props => props.theme.colors.borderColor};
      border-radius: ${props => props.theme.misc.borderRadius};
      background: #fff;
      padding: 0;
      outline: none;
      cursor: pointer;

      &:hover {
        border-color: ${props => props.theme.colors.primary};
      }

      &:disabled {
        border-color: ${props => props.theme.colors.borderColor};

        .icon {
          fill: ${props => props.theme.colors.etherealFade};
        }
      }

      .icon {
        pointer-events: none;
        vertical-align: middle;
        fill: ${props => props.theme.colors.primary};
      }
    }
  }

  .number-selector__value {
    font-size: 12px;
    line-height: 16px;
  }
`;
