import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import { styled } from 'styles';
import { REGEXP } from 'consts';
import getFirstFormError from 'utils/getFirstFormError';
import useLayout from 'hooks/useLayout';
import { Input, Button } from 'components';
import InputPlaces from 'components/InputPlaces';
import NoSSR from 'components/NoSSR';
import SelectedPackage from './SelectedPackage';
import FlatRateContext from './context';

interface PropertyInfoProps {
  data?: ProfileData;
}

const PropertyInfo: React.FC<PropertyInfoProps> = ({ data }) => {
  const { t } = useTranslation();
  const { register, control, errors, handleSubmit, formState } = useForm();
  const { total, selectedPackage, setPropertyInfo, setDisablePayment } = useContext(
    FlatRateContext
  );
  const layout = useLayout();
  const firstError = getFirstFormError(errors);

  const submit = values => {
    setPropertyInfo(values);

    setTimeout(() => {
      const addonsSections = document.querySelector('#addons-section');
      addonsSections?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
  };

  const checkKeyDown = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  useEffect(() => {
    if (formState.submitCount !== 0) setDisablePayment(!formState.isValid);
  }, [formState.submitCount, formState.isValid, setDisablePayment]);

  return (
    <StyledPropertyInfo className="property-info" id="property-info-block">
      <h3 className="property-info__title">{t('sell_page.step2')}</h3>
      <NoSSR>
        {layout === 'mobile' && (
          <div className="package-section-bottom-info">
            <SelectedPackage className="bottom-package" total={total!} {...selectedPackage!} />
          </div>
        )}
      </NoSSR>
      <form className="property-form" onSubmit={handleSubmit(submit)} onKeyDown={checkKeyDown}>
        <Controller
          control={control}
          name="address"
          defaultValue=""
          rules={{
            required: 'Required',
            maxLength: {
              value: 100,
              message: `Address can not exceed 100 characters`
            },
            pattern: {
              value: REGEXP.ADDRESS,
              message: 'Invalid Address'
            }
          }}
          render={controllerProps => (
            <InputPlaces
              label="Street Address"
              placeholder={t('sell_page.placeholder_street_address')}
              error={errors.address}
              className="property-form__item"
              {...controllerProps}
            />
          )}
        />

        <Input
          ref={register({
            required: 'Required',
            maxLength: {
              value: 55,
              message: `Name can not exceed 55 characters`
            },
            pattern: {
              value: REGEXP.NAME,
              message: 'Invalid name'
            }
          })}
          name="name"
          label="Name"
          placeholder={t('sell_page.placeholder_name')}
          error={errors.name}
          className="property-form__item"
        />
        <div className="property-form__container">
          <Controller
            control={control}
            name="phone"
            defaultValue=""
            rules={{
              required: 'Required',
              validate: value => !value.includes('_') || 'Required'
            }}
            render={controllerProps => (
              <Input
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                label="Phone"
                placeholder={t('sell_page.placeholder_phone')}
                type="tel"
                error={errors.phone}
                className="property-form__item"
                {...controllerProps}
              />
            )}
          />
          <Input
            ref={register({
              required: 'Required',
              maxLength: {
                value: 254,
                message: `Email can not exceed 254 characters`
              },
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email format'
              }
            })}
            defaultValue={data?.email}
            name="email"
            label="Email"
            placeholder={t('sell_page.placeholder_email')}
            type="email"
            error={errors.email}
            className="property-form__item"
          />
        </div>
        <Input
          ref={register()}
          as="textarea"
          name="comment"
          label="Comment"
          placeholder={t('sell_page.placeholder_comment')}
          className="property-form__item property-form__comment"
        />
        <p className="property-form__error">{firstError}</p>
        <Button type="submit" primary className="property-info__next-btn">
          {t('sell_page.next')}
        </Button>
      </form>
    </StyledPropertyInfo>
  );
};

const StyledPropertyInfo = styled.div`
  padding: 0 0 72px;
  .property-info {
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 24px;
    }
    &__next-btn {
      font-size: 12px;
      line-height: 14px;
      min-width: 123px;
    }
  }
  .property-form {
    &__item {
      width: 100%;
      margin: 0 0 20px;
      .input {
        &__component {
          height: 40px;
        }
        &__label {
          font-weight: normal;
        }
      }
    }
    &__container {
      display: grid;
      grid-gap: 0 24px;
      grid-template-columns: repeat(2, 1fr);
      .property-form__item {
        margin: 0 0 24px;
      }
    }
    &__error {
      font-size: 12px;
      color: ${props => props.theme.colors.errorRed};
      &:empty {
        display: none;
      }
    }
    &__comment {
      .input__component {
        height: 80px;
        overflow-y: auto;
        resize: none;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .property-info {
      &__title {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 16px;
      }
      &__next-btn {
        width: 100%;
      }
    }
    .property-form {
      &__container {
        grid-gap: 0;
        grid-template-columns: 1fr;
        .property-form__item {
          margin: 0 0 20px;
        }
      }
    }
    .selected-package {
      margin: 0 0 20px;
    }
  }
`;

export default PropertyInfo;
