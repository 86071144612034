import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-input-mask';
import clsx from 'clsx';

import notify from 'notify';
import { styled } from 'styles';
import { REGEXP } from 'consts';
import sendMessage from 'api/contact';
import { Button, Icon, Input } from 'components';

interface ContactFormProps {
  className?: string;
  formTitle?: string;
  isHiddenFormTitle?: boolean;
  isHiddenTelAgent?: boolean;
  attachFilePlaceholder?: string;
  isShowingAttach?: boolean;
  source?: string;
  type?: ContactType;
  formValues?: Partial<ContactFormValues>;
  submitButtonText?: string;
  onSubmit?: () => void;
  cancel?: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({
  className,
  formValues,
  formTitle = 'We’ll guide you with data and expertise specific to your area.',
  source = 'Main Page',
  type = 'Contact Request',
  submitButtonText = 'Send',
  attachFilePlaceholder = 'Attach CV...',
  isShowingAttach = false,
  isHiddenFormTitle,
  isHiddenTelAgent,
  onSubmit: onSubmitProps,
  cancel,
  ...props
}) => {
  const { t } = useTranslation();
  const { register, control, errors, handleSubmit, formState, reset } = useForm();

  const firstError = Object.values(errors)[0]?.message;

  const onSubmit = async (values: ContactFormValues) => {
    const data = formValues ? { ...formValues, ...values } : { ...values, source, type };

    try {
      await sendMessage(data);
      notify('Message was sent successfully.');
      onSubmitProps?.();
      reset();
    } catch (err) {
      notify(err.message);
    }
  };

  return (
    <StyledContactForm className={clsx('contact-form', className)} {...props}>
      {isHiddenFormTitle ? null : <h4 className="contact-form__title">{formTitle}</h4>}
      <form className="contact-form__form" onSubmit={handleSubmit(onSubmit)}>
        {firstError && (
          <div className="contact-form__error">
            <Icon name="warning" />
            {firstError}
          </div>
        )}
        <Input
          name="name"
          placeholder={t('contact_form.name')}
          className="contact-form__input"
          ref={register({
            required: 'Required',
            maxLength: {
              value: 50,
              message: 'Max length exceeded'
            },
            pattern: {
              value: /^[a-z0-9 ,.'-]+$/i,
              message: 'Invalid name'
            }
          })}
          error={errors.name}
        />
        <Input
          name="email"
          placeholder={t('contact_form.email')}
          className="contact-form__input"
          ref={register({
            required: 'Required',
            maxLength: {
              value: 254,
              message: `Email can not exceed 254 characters`
            },
            pattern: {
              value: REGEXP.EMAIL,
              message: 'Invalid email format'
            }
          })}
          error={errors.email}
        />
        <Controller
          control={control}
          name="phone"
          defaultValue=""
          rules={{
            required: 'Required',
            validate: value => !value.includes('_') || 'Required'
          }}
          render={controllerProps => (
            <Input
              as={MaskedInput}
              mask="+1 (999) 999-9999"
              placeholder={t('sell_page.placeholder_phone')}
              type="tel"
              error={errors.phone}
              className="contact-form__input"
              {...controllerProps}
            />
          )}
        />
        {isShowingAttach ? (
          <Input
            name="file"
            placeholder={attachFilePlaceholder}
            className="contact-form__input"
            after={<Icon name="file" />}
          />
        ) : null}
        <Input
          as="textarea"
          name="message"
          className="contact-form__textarea"
          placeholder={t('contact_form.your_message')}
          ref={register({
            required: 'Required'
          })}
          error={errors.message}
        />
        <div className="contact-form__buttons">
          {cancel && (
            <Button className="contact-form__button" onClick={cancel} primary outline>
              Cancel
            </Button>
          )}
          <Button
            type="submit"
            className="contact-form__button"
            primary
            disabled={formState.isSubmitting}>
            {submitButtonText}
          </Button>
        </div>
      </form>
      {isHiddenTelAgent ? null : (
        <p className="contact-form__contacts">
          {t('contact_form.footer')}
          <a href={`tel: ${t('contact_form.agent_phone')}`} className="contact-form__contacts-link">
            {t('contact_form.agent_phone')}
          </a>
        </p>
      )}
    </StyledContactForm>
  );
};

const StyledContactForm = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 360px;

  .contact-form {
    &__form {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 0 28px;
      width: 100%;
    }

    &__input {
      margin: 0 0 16px;
      width: 100%;
    }
    &__buttons {
      display: flex;
      justify-content: center;
      width: 100%;
      .button:nth-of-type(2) {
        margin-left: 16px;
      }
    }
    &__button {
      width: 240px;
    }
    &__textarea {
      width: 100%;
      margin: 0 0 32px;
      .input__component {
        min-height: 112px;
        resize: none;
      }
    }
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.4;
      margin: 0 0 32px;
      text-align: center;
    }
    &__contacts {
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }
    &__contacts-link {
      color: ${props => props.theme.colors.black};
      text-decoration: none;
      font-weight: 500;
    }
    &__error {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: ${props => props.theme.colors.red};
      margin-bottom: 8px;

      .icon {
        margin-right: 4px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    .contact-form {
      &__contacts {
        max-width: 230px;
        text-align: center;
      }
      &__button {
        width: 100%;
      }
    }
  }
`;

export default ContactForm;
