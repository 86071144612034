import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import check from './check.svg';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  label?: React.ReactNode;
  large?: boolean;
  ref?: any;
}

const Checkbox: React.FC<CheckboxProps> = React.forwardRef(
  ({ id, label, disabled, name, className, ...props }, ref) => {
    return (
      <StyledCheckbox
        className={clsx('checkbox', { label, disabled }, className)}
        data-cy={`checkbox_${name}`}>
        <input ref={ref} type="checkbox" id={id} name={name} {...props} />{' '}
        <label htmlFor={id}>{label}</label>
      </StyledCheckbox>
    );
  }
);

export default React.memo(Checkbox);

const StyledCheckbox = styled.div`
  display: inline-flex;
  align-items: center;
  width: 16px;

  &.label {
    width: auto;
  }

  input {
    width: 16px;
    height: 16px;
    background: white;
    border: 1px solid #cdcdcd;
    border-radius: ${props => props.theme.misc.borderRadius};
    box-sizing: border-box;
    appearance: none;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    outline: none;

    &:checked {
      background: ${props => props.theme.colors.primary};
      border: none;

      ::after {
        content: '';
        background-image: url(${check});
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }

  label {
    color: black;
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    line-height: 16px;
  }

  &.disabled {
    input {
      pointer-events: none;
      opacity: 0.5;
    }

    label {
      color: #8b8b8b;
    }
  }
`;
