import i18next from 'i18next';
import { locales } from 'sitedata';

i18next.init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: locales.en
    }
  },
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    wait: true
  }
});

i18next.languages = ['en'];

export default i18next;
