import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';
import Progress from 'react-circle-progress-bar';
import { styled } from 'styles';

interface TimerProps extends React.HTMLAttributes<HTMLDivElement> {
  time?: number;
  text?: (value: { percentage: number; timeLeft: number }) => React.ReactNode;
  onTimeout?: () => void;
}

const Timer: React.FC<TimerProps> = ({ className, time = 5, text, onTimeout }) => {
  const timer = useRef<any>(null);
  const [timeLeft, setTimeLeft] = useState(time);
  const [isCounting, setIsCounting] = useState(false);
  const percentage = 100 - (timeLeft / time) * 100;

  useEffect(() => {
    if (timer.current === null) {
      timer.current = setInterval(() => setTimeLeft(timeLeft => timeLeft - 1), 1000);
      setIsCounting(true);
    }
    if (isCounting && !timeLeft) {
      clearInterval(timer.current);
      setIsCounting(false);
      onTimeout?.();
    }
  }, [isCounting, timeLeft, onTimeout]);

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <StyledTimer className={clsx('timer', className)}>
      <Progress
        progress={percentage}
        hideBall
        reduction={0}
        strokeWidth={20}
        background={null}
        className="progress"
      />
      {text && text({ percentage, timeLeft })}
    </StyledTimer>
  );
};

export default React.memo(Timer);

const StyledTimer = styled.div`
  display: inline-flex;
  align-items: center;

  .progress {
    width: 16px;
    margin-right: 10px;

    circle:nth-of-type(2) {
      stroke: ${props => props.theme.colors.primary};
    }
  }

  text {
    display: none;
  }
`;
