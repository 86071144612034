import React, { useState, useContext, useEffect } from 'react';
import { styled } from 'styles';
import clsx from 'clsx';
import { NoSSR } from 'components';

import { Components } from 'sitedata';
import { getFirstError } from 'utils';
import notify from 'notify';
import { AuthContext, AuthModalContext } from 'auth';
import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import SocialButton from '../SocialButton';
import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';
import ResetPasswordForm from './ResetPasswordForm';
import ForgotPasswordForm from './ForgotPasswordForm';

type TabKey = 'sign_in' | 'sign_up';
// type ViewKey = TabKey | 'forgot_password' | 'reset_password';

const tabs: { key: TabKey; text: string }[] = [
  { key: 'sign_in', text: 'Sign In' },
  { key: 'sign_up', text: 'Sign Up' }
];

const AuthModal = () => {
  const { authorized, loginGoogle, loginFacebook } = useContext(AuthContext);
  const {
    authModalOpen,
    setAuthModalOpen,
    forceOpen,
    setForceOpen,
    activeView,
    setActiveView,
    message,
    resetPasswordToken,
    setResetPasswordToken
  } = useContext(AuthModalContext);
  const [success, setSuccess] = useState<string>('');
  const showTabs = activeView === 'sign_in' || activeView === 'sign_up';

  const signInGoogle = async (token: string) => {
    try {
      await loginGoogle(token);
      setAuthModalOpen(false);
    } catch (err) {
      notify(getFirstError(err) || err.message);
    }
  };

  const signInFacebook = async (token: string) => {
    try {
      await loginFacebook(token);
      setAuthModalOpen(false);
    } catch (err) {
      notify(getFirstError(err) || err.message);
    }
  };

  const handleClose = () => {
    setAuthModalOpen(false);
    setSuccess('');
    setActiveView('sign_in');
  };

  useEffect(() => {
    if (authorized && !authModalOpen) setForceOpen(false);
  }, [authModalOpen, authorized, setForceOpen]);

  const title =
    // eslint-disable-next-line no-nested-ternary
    activeView === 'reset_password'
      ? 'Set new password'
      : activeView === 'forgot_password'
      ? 'Enter Email to reset password'
      : '';

  const getForm = () => {
    switch (activeView) {
      case 'forgot_password':
        return (
          <ForgotPasswordForm
            onSuccess={() => {
              setSuccess(
                'Request submitted. A link to reset your \npassword has been sent to your email.'
              );
              setActiveView('sign_in');
            }}
            onReturnClick={() => setActiveView('sign_in')}
          />
        );

      case 'reset_password':
        if (!resetPasswordToken) return <>No token</>;
        return (
          <ResetPasswordForm
            token={resetPasswordToken}
            onSuccess={() => {
              setActiveView('sign_in');
              setSuccess('Your password is successfully changed. \nYou can log in now.');
              setResetPasswordToken(undefined);
            }}
            onReturnClick={() => {
              setActiveView('sign_in');
              setResetPasswordToken(undefined);
            }}
          />
        );

      case 'sign_in':
        return (
          <SignInForm
            onSuccess={handleClose}
            onForgotPassowordClick={() => setActiveView('forgot_password')}
          />
        );

      case 'sign_up':
        return <SignUpForm onSuccess={handleClose} />;

      default:
        return null;
    }
  };

  return (
    <StyledModal
      open={forceOpen || authModalOpen}
      modalTitle={<Components.Logo />}
      onClose={handleClose}
      hideClose={forceOpen}
      className="auth-modal">
      {success ? (
        <StyledSuccessMessage className="success-message">
          <Icon name="success-circle" />
          <p>{success}</p>
          <Button primary onClick={handleClose}>
            Close
          </Button>
        </StyledSuccessMessage>
      ) : (
        <>
          <p className="auth-modal__message">{message}</p>
          {showTabs && (
            <div className="auth-modal__tabs">
              {tabs.map(tab => (
                <button
                  key={tab.key}
                  onClick={() => setActiveView(tab.key)}
                  className={clsx({ active: activeView === tab.key })}>
                  {tab.text}
                </button>
              ))}
            </div>
          )}
          {title && <h4 className="auth-modal__title">{title}</h4>}
          {getForm()}
          <hr />
          <NoSSR>
            <SocialButton onSuccess={result => signInGoogle(result.token)} provider="google">
              <Icon name="social-google" /> Continue with Google
            </SocialButton>
            <SocialButton onSuccess={result => signInFacebook(result.token)} provider="facebook">
              <Icon name="social-facebook" /> Continue with Facebook
            </SocialButton>
          </NoSSR>

          <div className="auth-modal__terms-privacy">
            <p className="auth-modal__privacy-link-container">
              {activeView === 'sign_up' && (
                <span className="auth-modal__privacy-link-text">By clicking Sign up I accept</span>
              )}{' '}
              <a href="/privacy" target="_blank" className="auth-modal__privacy-link">
                Terms and Conditions and Privacy Policy
              </a>
            </p>
          </div>
        </>
      )}
    </StyledModal>
  );
};

export default React.memo(AuthModal);

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 360px;
  }

  .modal-head {
    padding: 16px 16px 20px;
    text-align: center;

    .title {
      line-height: initial;

      .logo {
        vertical-align: middle;
        max-width: 150px;
        height: 16px;
      }
    }

    .button-close {
      margin-top: 2px;
    }
  }

  .modal-body {
    padding: 0 40px 24px 40px;
  }

  .auth-modal__auth-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 4px 0 24px 0;
  }

  .auth-modal__message {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .auth-modal__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;

    button {
      background: none;
      border: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #979797;
      cursor: pointer;
      outline: none;

      &:first-of-type {
        margin-right: 20px;
      }

      &.active {
        text-decoration: underline;
        color: #000000;
      }
    }
  }

  .auth-modal__title {
    margin: 0 0 42px;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
  }

  form {
    .input {
      width: 100%;
      margin: 8px 0;
    }

    .button {
      font-weight: 500;

      &.submit {
        margin: 16px 0;
      }
      &.forgot-pass {
        width: auto;
        margin: auto;

        &:hover {
          background: transparent;
        }
      }
    }

    .error {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: ${props => props.theme.colors.red};
      margin-bottom: 8px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    margin: 6px 0;

    .icon {
      margin-right: 8px;
    }
  }

  hr {
    border-color: ${props => props.theme.colors.borderColor};
    margin: 24px 0;
  }

  .auth-modal {
    &__terms-privacy {
      text-align: center;
      font-size: 10px;
      line-height: 12px;
      margin-top: 24px;

      a {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
    }
    &__privacy-link-container {
      margin: 0;
    }
    &__privacy-link-text {
      display: block;
      margin: 0 0 4px;
    }
  }
`;

const StyledSuccessMessage = styled.div`
  margin-top: 24px;
  text-align: center;

  .icon {
    stroke: ${props => props.theme.colors.primary};
  }

  .icon path {
    fill: ${props => props.theme.colors.primary};
  }

  p {
    margin: 24px 10px 42px 10px;
    font-size: 14px;
    line-height: 24px;
  }
`;
